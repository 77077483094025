'use client';

import { Label } from '@radix-ui/react-dropdown-menu';

import Typography from '@/src/components/Typography';

import { type MenuHeadingProps } from './MenuHeading.props';
import { MenuHeadingVariants } from './MenuHeading.variants';

export const MenuHeading = ({
  children,
  className,
  truncate = false,
  typographyVariant = 'caption2',
  ...props
}: MenuHeadingProps) => (
  <Label
    asChild
    className={MenuHeadingVariants({ className })}
    {...props}
    data-ids="MenuHeading"
  >
    <Typography
      disableColorScheme
      maxLines={truncate ? 1 : undefined}
      variant={typographyVariant}
    >
      {children}
    </Typography>
  </Label>
);
MenuHeading.displayName = 'MenuHeading';

export default MenuHeading;
