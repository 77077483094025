'use client';

import { forwardRef } from 'react';
import {
  Arrow,
  Content,
  Portal,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip';

import Typography from '@/src/components/Typography';
import ConditionalWrapper from '@/src/utils/ConditionalWrapper';
import reactNodeToText from '@/src/utils/reactNodeToText';

import { type TooltipProps } from './Tooltip.props';
import { TooltipVariants } from './Tooltip.variants';

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      align = 'center',
      arrow = true,
      children,
      className,
      container,
      content,
      disableAnimation = false,
      enterDelay = 100,
      enterNextDelay = 0,
      onOpenChange,
      open,
      side = 'top',
      theme = 'auto',
      title,
      typographyVariant = 'caption1',
      ...props
    },
    ref,
  ) => {
    const tooltipContent = content || title;
    const compact = ['string', 'number'].includes(typeof tooltipContent);

    return (
      <Provider delayDuration={enterDelay} skipDelayDuration={enterNextDelay}>
        <Root onOpenChange={onOpenChange} open={open}>
          <Trigger aria-label={reactNodeToText(tooltipContent)} asChild>
            {children}
          </Trigger>
          <Portal container={container}>
            <Content
              align={align}
              className={TooltipVariants({
                className,
                compact,
                disableAnimation,
                theme,
              })}
              data-testid="tooltip-content"
              side={side}
              sideOffset={5}
              {...props}
              data-ids="Tooltip"
              ref={ref}
            >
              <ConditionalWrapper
                condition={compact}
                wrapper={(tooltipContent) => (
                  <Typography disableColorScheme variant={typographyVariant}>
                    {tooltipContent}
                  </Typography>
                )}
              >
                {tooltipContent}
              </ConditionalWrapper>
              {arrow && <Arrow />}
            </Content>
          </Portal>
        </Root>
      </Provider>
    );
  },
);
Tooltip.displayName = 'Tooltip';

export default Tooltip;
