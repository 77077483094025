'use client';

import { forwardRef, useId } from 'react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Content,
  Group,
  Portal,
  Root,
  ScrollDownButton,
  ScrollUpButton,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';
import { cx } from 'class-variance-authority';

import InputLabel from '@/src/components/InputLabel';
import { MenuContentVariants } from '@/src/components/Menu/MenuContent/MenuContent.variants';
import Typography from '@/src/components/Typography';

import { type SelectProps } from './Select.props';
import { SelectScrollVariants, SelectVariants } from './Select.variants';

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      'aria-label': ariaLabel,
      children,
      className,
      compact = false,
      container,
      disabled = false,
      error = false,
      id,
      label,
      name,
      onCloseAutoFocus,
      onEscapeKeyDown,
      onOpenChange,
      onPointerDownOutside,
      parentRef,
      placeholder = 'Select an option',
      required = false,
      scrollable = true,
      theme = 'auto',
      variant = 'outlined',
      ...props
    },
    ref,
  ) => {
    const componentId = id || useId();

    return (
      <div
        className={cx(className, {
          dark: theme === 'dark',
        })}
        data-ids="Select"
        ref={parentRef}
      >
        {label && (
          <InputLabel
            className="mb-3 block"
            disabled={disabled}
            error={error}
            htmlFor={componentId}
          >
            {label}
            {required && (
              <span className="ml-1 text-error-2 dark:text-error-3">*</span>
            )}
          </InputLabel>
        )}
        <Root disabled={disabled} name={name} required={required} {...props}>
          <Trigger aria-label={ariaLabel || label} asChild ref={ref}>
            <Typography
              as="button"
              className={SelectVariants({
                compact,
                disabled,
                error,
                theme,
                variant,
              })}
              disableColorScheme
              id={componentId}
              variant="body2"
            >
              <Value placeholder={placeholder} />
              <Icon className="min-w-6" path={mdiChevronDown} size={1} />
            </Typography>
          </Trigger>
          <Portal container={container}>
            <Content
              className={cx(
                MenuContentVariants({ collapsePadding: true, theme }),
                'w-[--radix-select-trigger-width]',
                {
                  'max-h-96': scrollable,
                },
              )}
              onCloseAutoFocus={onCloseAutoFocus}
              onEscapeKeyDown={onEscapeKeyDown}
              onPointerDownOutside={onPointerDownOutside}
              position="popper"
              sideOffset={4}
            >
              {scrollable && (
                <ScrollUpButton className={SelectScrollVariants({ theme })}>
                  <Icon path={mdiChevronUp} size={1} />
                </ScrollUpButton>
              )}
              <Viewport>
                <Group className="flex flex-col gap-2 py-2">{children}</Group>
              </Viewport>
              {scrollable && (
                <ScrollDownButton className={SelectScrollVariants({ theme })}>
                  <Icon path={mdiChevronDown} size={1} />
                </ScrollDownButton>
              )}
            </Content>
          </Portal>
        </Root>
      </div>
    );
  },
);
Select.displayName = 'Select';

export default Select;
