'use client';

import { forwardRef } from 'react';

import { useCarousel } from '../Carousel.context';
import { type CarouselContentProps } from './CarouselContent.props';
import { CarouselContentVariants } from './CarouselContent.variants';

export const CarouselContent = forwardRef<HTMLDivElement, CarouselContentProps>(
  ({ children, className, ...props }, ref) => {
    const { carouselRef, variant } = useCarousel();

    const totalChildren = Array.isArray(children) ? children.length : 1;
    const scrollOverflow =
      totalChildren > 4
        ? 'large'
        : totalChildren > 3
          ? 'medium'
          : totalChildren > 2
            ? 'small'
            : 'none';

    return (
      <div
        className="w-full overflow-hidden"
        data-ids="CarouselContent"
        ref={carouselRef}
      >
        <div
          className={CarouselContentVariants({
            className,
            scrollOverflow,
            variant,
          })}
          {...props}
          ref={ref}
        >
          {children}
        </div>
      </div>
    );
  },
);
CarouselContent.displayName = 'CarouselContent';

export default CarouselContent;
