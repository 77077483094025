'use client';

import { type UrlObject } from 'url';
import { forwardRef, useState } from 'react';
import {
  Content,
  Item,
  Link,
  List,
  Root,
  Trigger,
} from '@radix-ui/react-navigation-menu';

import Typography from '@/src/components/Typography';

import { type GlobalHeaderNavigationMenuProps } from './GlobalHeaderNavigationMenu.props';
import {
  GlobalHeaderNavigationMenuItemVariants,
  GlobalHeaderNavigationMenuVariants,
} from './GlobalHeaderNavigationMenu.variants';

export const GlobalHeaderNavigationMenu = forwardRef<
  HTMLDivElement,
  GlobalHeaderNavigationMenuProps
>(
  (
    {
      activePath,
      brand = 'motortrend',
      className,
      items,
      linkElement = 'a',
      theme = 'auto',
      ...props
    },
    ref,
  ) => {
    const [openMenu, setOpenMenu] = useState<string | undefined>(undefined);

    const renderMenuItems = () =>
      items.map((item, i: number) => {
        if (!item.displayInHeader) {
          return;
        }

        const itemKey = i.toString();
        const itemPathName =
          (typeof item.href === 'string'
            ? item.href
            : (item.href as UrlObject)?.pathname) || '';

        const isActive = activePath?.startsWith(itemPathName);
        const LinkElement = item?.linkElement || linkElement;
        return (
          <Item className="h-full" key={i} value={itemKey}>
            <Trigger asChild onFocus={() => setOpenMenu(itemKey)}>
              <LinkElement
                className={GlobalHeaderNavigationMenuItemVariants({
                  active: isActive,
                  brand,
                  open: openMenu === itemKey,
                })}
                data-id="global-header-link"
                data-parent="global-header"
                href={item.href || '#'}
                onClick={() => setOpenMenu('none')}
              >
                <Typography disableColorScheme noWrap variant="caption1">
                  {item.title}
                </Typography>
              </LinkElement>
            </Trigger>
            {!!item?.children?.length && (
              <Content className="absolute z-top mt-1 w-64 rounded-lg bg-neutral-1 p-2">
                <ul className="max-h-[70vh] overflow-y-auto scrollbar-dark">
                  {item.children.map((child, c: number) => {
                    const LinkElement = child?.linkElement || linkElement;
                    return (
                      <Typography as="li" key={`${i}-${c}`} variant="caption1">
                        <LinkElement href={child.href} legacyBehavior passHref>
                          <Link
                            className="block rounded-lg p-2 text-neutral-5 hover:bg-neutral-2 hover:text-neutral-8 focus:bg-neutral-2 focus:outline-0"
                            data-id="global-header-link"
                            data-parent="global-header"
                          >
                            <Typography disableColorScheme variant="caption1">
                              {child.title}
                            </Typography>
                          </Link>
                        </LinkElement>
                      </Typography>
                    );
                  })}
                </ul>
              </Content>
            )}
          </Item>
        );
      });

    return (
      <Root
        className={GlobalHeaderNavigationMenuVariants({ className, theme })}
        onValueChange={setOpenMenu}
        value={openMenu}
        {...props}
        data-ids="GlobalHeaderNavigationMenu"
        ref={ref}
      >
        <List className="group flex h-full flex-1 list-none items-center">
          {renderMenuItems()}
        </List>
      </Root>
    );
  },
);
GlobalHeaderNavigationMenu.displayName = 'GlobalHeaderNavigationMenu';

export default GlobalHeaderNavigationMenu;
