'use client';

import { createContext, useContext } from 'react';

import { type CarouselContextProps } from './Carousel.props';

export const CarouselContext = createContext<CarouselContextProps | null>(null);

export const useCarousel = () => {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
};
