'use client';

import { forwardRef } from 'react';

import { useCarousel } from '../Carousel.context';
import { type CarouselItemProps } from './CarouselItem.props';
import { CarouselItemVariants } from './CarouselItem.variants';

export const CarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(
  ({ children, className, ...props }, ref) => {
    const { variant } = useCarousel();

    return (
      <div
        aria-roledescription="slide"
        className={CarouselItemVariants({ className, variant })}
        role="group"
        {...props}
        data-ids="CarouselItem"
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
CarouselItem.displayName = 'CarouselItem';

export default CarouselItem;
