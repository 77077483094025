'use client';

import { forwardRef, useContext } from 'react';

import { DialogContext } from '../Dialog.context';
import { type DialogFooterProps } from './DialogFooter.props';
import { DialogFooterVariants } from './DialogFooter.variants';

export const DialogFooter = forwardRef<HTMLDivElement, DialogFooterProps>(
  ({ children, className, ...props }, ref) => {
    const context = useContext(DialogContext);
    if (!context) {
      throw new Error('DialogFooter must be used within Dialog.');
    }

    return (
      <div
        className={DialogFooterVariants({ className })}
        {...props}
        data-ids="DialogFooter"
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
DialogFooter.displayName = 'DialogFooter';

export default DialogFooter;
