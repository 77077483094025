'use client';

import { forwardRef } from 'react';
import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog';
import { cx } from 'class-variance-authority';

import { type ModalProps } from './Modal.props';

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      className,
      container,
      disabledEscapeKeyDown = false,
      hideBackdrop = false,
      onClose = () => {},
      open = false,
      role = 'dialog',
      ...props
    },
    ref,
  ) => {
    return (
      <Root open={open}>
        <Portal container={container}>
          {!hideBackdrop && (
            <Overlay
              className="fixed inset-0 z-top bg-neutral-1/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
              data-ids="ModalOverlay"
            />
          )}
          <Content
            className={cx('fixed z-top outline-none', className)}
            data-ids="ModalContent"
            onEscapeKeyDown={(event) => {
              if (disabledEscapeKeyDown) {
                return event.preventDefault();
              }
              return onClose(event, 'escapeKeyDown');
            }}
            onOpenAutoFocus={(event) => {
              // The default Radix behavior is to focus the first
              // form element in a "Dialog." That's not what we want
              // by default for the general Modal component. Therefore,
              // we simply put focus on the child container so normal
              // keyboard operation is captured within it and can be
              // controlled normally.
              event.preventDefault();
              (event.target as HTMLDivElement).focus();
            }}
            onPointerDownOutside={(event) => onClose(event, 'backdropClick')}
            role={role}
            {...props}
            ref={ref}
            tabIndex={-1}
          >
            {children}
          </Content>
        </Portal>
      </Root>
    );
  },
);
Modal.displayName = 'Modal';

export default Modal;
