'use client';

import { forwardRef } from 'react';
import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';

import { useCarousel } from '../Carousel.context';
import { type CarouselPreviousProps } from './CarouselPrevious.props';
import { CarouselPreviousVariants } from './CarouselPrevious.variants';

export const CarouselPrevious = forwardRef<
  HTMLButtonElement,
  CarouselPreviousProps
>(
  (
    {
      children = <Icon path={mdiChevronLeft} />,
      className,
      title = 'Previous slide',
      ...props
    },
    ref,
  ) => {
    const { canScrollPrev, scrollPrev, variant } = useCarousel();

    if (!canScrollPrev) {
      return;
    }

    return (
      <button
        className={className || CarouselPreviousVariants({ variant })}
        title={title}
        {...props}
        data-ids="CarouselPrevious"
        onClick={scrollPrev}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);
CarouselPrevious.displayName = 'CarouselPrevious';

export default CarouselPrevious;
