'use client';

import { type FunctionComponent } from 'react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Close,
  Description,
  Provider,
  Root,
  Title,
  Viewport,
  type ToastProviderProps,
} from '@radix-ui/react-toast';

import IconButton from '@/src/components/IconButton';
import Typography from '@/src/components/Typography';
import ConditionalWrapper from '@/src/utils/ConditionalWrapper';
import useBreakpoint from '@/src/utils/useBreakpoint';

import { ToastVariants } from './Toast.variants';
import { useToast } from './useToast';

export const ToastProvider: FunctionComponent = () => {
  const { toasts } = useToast();
  const isMobile = !useBreakpoint('sm');

  return toasts.map(
    ({
      className,
      description,
      duration = 5000,
      horizontal = 'right',
      id,
      severity = undefined,
      startAdornment,
      title,
      type = 'foreground',
      vertical = 'bottom',
      ...rest
    }) => {
      return (
        <Provider
          duration={duration}
          key={id}
          swipeDirection={
            isMobile
              ? 'right'
              : (horizontal as ToastProviderProps['swipeDirection'])
          }
        >
          <Root
            className={ToastVariants({
              className,
              horizontal,
              severity,
              vertical,
            })}
            type={type}
            {...rest}
            data-ids="Toast"
            data-severity={severity}
          >
            <div className="flex items-center gap-4">
              {startAdornment && (
                <div className="min-w-12">{startAdornment}</div>
              )}
              <div className="flex flex-col gap-2 pr-6">
                {title && (
                  <Title>
                    <ConditionalWrapper
                      condition={typeof title === 'string'}
                      wrapper={(title) => (
                        <Typography disableColorScheme variant="h6">
                          {title}
                        </Typography>
                      )}
                    >
                      {title}
                    </ConditionalWrapper>
                  </Title>
                )}
                {description && (
                  <Description>
                    <ConditionalWrapper
                      condition={typeof description === 'string'}
                      wrapper={(description) => (
                        <Typography disableColorScheme variant="body3">
                          {description}
                        </Typography>
                      )}
                    >
                      {description}
                    </ConditionalWrapper>
                  </Description>
                )}
              </div>
            </div>
            <Close asChild>
              <IconButton
                aria-label="Close"
                className="absolute right-4 top-4"
                colorScheme="transparent"
                compact
                ripple={false}
                rounded
              >
                <Icon aria-hidden path={mdiClose} size={1} />
              </IconButton>
            </Close>
          </Root>
          <Viewport className="pointer-events-none fixed inset-0 z-top h-full w-full" />
        </Provider>
      );
    },
  );
};
ToastProvider.displayName = 'ToastProvider';

export default ToastProvider;
