'use client';

import { forwardRef } from 'react';
import { cx } from 'class-variance-authority';

import Modal from '@/src/components/Modal';

import { type DrawerProps } from './Drawer.props';
import { DrawerVariants } from './Drawer.variants';

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  ({ anchor = 'left', children, className, theme = 'auto', ...props }, ref) => {
    return (
      <Modal
        className={DrawerVariants({ anchor, className, theme })}
        {...props}
        data-ids="Drawer"
      >
        <div
          className={cx(className, {
            dark: theme === 'dark',
          })}
          {...props}
          ref={ref}
        >
          {children}
        </div>
      </Modal>
    );
  },
);
Drawer.displayName = 'Drawer';

export default Drawer;
