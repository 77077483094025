'use client';

import { forwardRef, useContext, useEffect, useId } from 'react';

import Typography from '@/src/components/Typography';

import { DialogContext } from '../Dialog.context';
import { type DialogTitleProps } from './DialogTitle.props';
import { DialogTitleVariants } from './DialogTitle.variants';

export const DialogTitle = forwardRef<HTMLDivElement, DialogTitleProps>(
  (
    { children, className, hideBorder = false, variant = 'h6', ...props },
    ref,
  ) => {
    const context = useContext(DialogContext);
    if (!context) {
      throw new Error('DialogTitle must be used within Dialog.');
    }

    const { setLabelledBy, showCloseButton } = context;
    const titleId = useId();

    useEffect(() => {
      setLabelledBy(titleId);
    }, [titleId]);

    return (
      <Typography
        as="h1"
        className={DialogTitleVariants({
          className,
          hideBorder,
          showCloseButton,
        })}
        id={titleId}
        variant={variant}
        {...props}
        data-ids="DialogTitle"
        ref={ref}
      >
        {children}
      </Typography>
    );
  },
);
DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
