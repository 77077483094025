'use client';

import { forwardRef } from 'react';
import { Root } from '@radix-ui/react-accordion';

import {
  type AccordionMultipleProps,
  type AccordionSingleProps,
} from './Accordion.props';
import { AccordionVariants } from './Accordion.variants';

export const Accordion = forwardRef<
  HTMLDivElement,
  AccordionSingleProps | AccordionMultipleProps
>(({ children, className, ...props }, ref) => {
  return (
    <Root
      className={AccordionVariants({ className })}
      {...props}
      data-ids="Accordion"
      ref={ref}
    >
      {children}
    </Root>
  );
});
Accordion.displayName = 'Accordion';

export default Accordion;
