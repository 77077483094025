'use client';

import {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  type KeyboardEvent,
} from 'react';
import { cx } from 'class-variance-authority';
import useEmblaCarousel from 'embla-carousel-react';

import { CarouselContext } from './Carousel.context';
import { type CarouselApi, type CarouselProps } from './Carousel.props';
import { CarouselVariants } from './Carousel.variants';

export const Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  (
    {
      as: Component = 'div',
      children,
      className,
      disableKeyboardControl = false,
      loop = false,
      opts,
      plugins,
      scroll = 'page',
      setApi,
      variant = 'filmstrip',
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        align: 'start',
        dragFree: scroll === 'page' && variant === 'filmstrip',
        loop,
        ...opts,
        axis: 'x',
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [pointerDown, setPointerDown] = useState(false);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const onPointerDown = useCallback(() => {
      setPointerDown(true);
    }, []);
    const onPointerUp = useCallback(() => {
      setPointerDown(false);
    }, []);

    const scrollPrev = useCallback(() => {
      if (scroll === 'page' && variant === 'filmstrip') {
        const slidesInView = api?.slidesInView() || [0];
        const slidesShown = slidesInView.length;
        const firstSlideInView = slidesInView.shift() || 0;
        const targetSlide = Math.max(firstSlideInView - (slidesShown - 1), 0);
        api?.scrollTo(targetSlide);
        return;
      }

      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      if (scroll === 'page' && variant === 'filmstrip') {
        const slidesInView = api?.slidesInView() || [0];
        api?.scrollTo(slidesInView.pop() || 0);
        return;
      }

      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);
      api.on('pointerDown', onPointerDown);
      api.on('pointerUp', onPointerUp);

      return () => {
        api?.off('select', onSelect);
        api?.off('pointerDown', onPointerDown);
        api?.off('pointerUp', onPointerUp);
      };
    }, [api, onSelect]);

    const isScrollable = canScrollNext || canScrollPrev;

    return (
      <CarouselContext.Provider
        value={{
          api,
          canScrollNext,
          canScrollPrev,
          carouselRef,
          opts,
          scrollNext,
          scrollPrev,
          variant,
        }}
      >
        <Component
          aria-roledescription="carousel"
          className={cx(CarouselVariants({ className }), {
            'cursor-grab': isScrollable && !pointerDown,
            'cursor-grabbing': isScrollable && pointerDown,
          })}
          onKeyDownCapture={!disableKeyboardControl ? handleKeyDown : undefined}
          ref={ref}
          role="region"
          {...props}
          data-ids="Carousel"
        >
          {children}
        </Component>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = 'Carousel';

export default Carousel;
