'use client';

import { forwardRef, type ElementRef } from 'react';
import { mdiAccountCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Fallback, Image, Root } from '@radix-ui/react-avatar';

import Typography from '@/src/components/Typography';

import { type AvatarProps } from './Avatar.props';
import { AvatarVariants } from './Avatar.variants';

export const Avatar = forwardRef<ElementRef<typeof Root>, AvatarProps>(
  (
    {
      alt,
      children,
      className,
      colorScheme = 'neutral-3',
      height,
      ignoreTheme = false,
      src,
      variant = 'circular',
      width,
      ...props
    },
    ref,
  ) => {
    const generateInitialCap = (text: string): string => {
      return text
        .replace(/([A-Z])/g, ' $1')
        .split(' ')
        .map((n: string) => n[0])
        .join('')
        .toUpperCase();
    };

    const generateFallbackContent = () => {
      if (typeof children === 'string') {
        return (
          <Typography className="!text-[50cqw]" disableColorScheme>
            {generateInitialCap(children)}
          </Typography>
        );
      } else if (alt) {
        return (
          <Typography className="!text-[50cqw]" disableColorScheme>
            {generateInitialCap(alt)}
          </Typography>
        );
      } else if (typeof children !== 'undefined') {
        return children;
      } else {
        return <Icon path={mdiAccountCircleOutline} size="80%" />;
      }
    };

    const renderContent = () => {
      if (src) {
        const altText = alt
          ? alt
          : typeof children === 'string'
            ? children
            : '';

        return (
          <>
            <Image
              alt={altText}
              className="aspect-square h-full w-full object-cover"
              src={src}
            />
            <Fallback className="flex h-full w-full items-center justify-center">
              {generateFallbackContent()}
            </Fallback>
          </>
        );
      }

      return (
        <div className="flex h-full w-full items-center justify-center">
          {generateFallbackContent()}
        </div>
      );
    };

    const style =
      height || width
        ? {
            height: `${height || width}rem`,
            width: `${width || height}rem`,
          }
        : {};

    return (
      <Root
        className={AvatarVariants({
          className,
          colorScheme,
          ignoreTheme,
          variant,
        })}
        style={style}
        {...props}
        data-ids="Avatar"
        ref={ref}
      >
        {renderContent()}
      </Root>
    );
  },
);
Avatar.displayName = 'Avatar';

export default Avatar;
