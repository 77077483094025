'use client';

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  type Ref,
} from 'react';
import { cx } from 'class-variance-authority';
import Hls from 'hls.js';
import { type PolyRefFunction } from 'react-polymorphed';

import { type CardMediaProps } from './CardMedia.props';
import {
  CardMediaContentVariants,
  CardMediaVariants,
} from './CardMedia.variants';

const forwardPolymorphicRef = forwardRef as PolyRefFunction;

export const CardMedia = forwardPolymorphicRef<'img', CardMediaProps>(
  (
    {
      alt = '',
      as: Component = 'img',
      aspectRatio = 'auto',
      children,
      className,
      disableImageOverflow = false,
      imageProps = {},
      responsiveAspectRatio = false,
      src,
      variant = 'rounded',
      videoProps = {},
      zoomOnHover = false,
      ...props
    },
    forwardedRef,
  ) => {
    const internalRef = useRef<HTMLImageElement | HTMLVideoElement>(null);
    useImperativeHandle(forwardedRef, () => internalRef.current!, []);
    useEffect(() => {
      if (Component === 'video' && internalRef.current) {
        const player = internalRef.current as HTMLVideoElement;
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(src);
          hls.attachMedia(player);
        } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
          player.src = src;
        }
      }
    }, [src, Component, internalRef]);

    const renderImage = () => {
      const { className, ...restImageProps } = imageProps;

      const imageClassName = cx(className, {
        '@sm:object-cover object-contain':
          responsiveAspectRatio && disableImageOverflow,
        'object-cover':
          !responsiveAspectRatio ||
          (responsiveAspectRatio && !disableImageOverflow),
      });

      const defaultProps =
        Component === 'img'
          ? // Default img
            {
              className: CardMediaContentVariants({
                aspectRatio,
                className: cx('absolute h-full w-full inset-0', imageClassName),
                responsiveAspectRatio,
                zoomOnHover,
              }),
            }
          : // Next Image
            {
              blurDataURL:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN89uJNPQAIsQM7E+c3WAAAAABJRU5ErkJggg==',
              className: imageClassName,
              fill: true,
              placeholder: 'blur',
              priority: false,
              quality: 75,
            };

      return (
        <Component
          alt={alt}
          ref={internalRef as Ref<HTMLImageElement>}
          sizes="100vw"
          src={src}
          {...defaultProps}
          {...restImageProps}
        />
      );
    };

    const renderVideo = () => {
      const { className: videoClassName, ...restVideoProps } = videoProps;
      return (
        <video
          className={CardMediaContentVariants({
            aspectRatio,
            className: cx('m-auto max-h-full', videoClassName),
            responsiveAspectRatio,
          })}
          controls
          src={src}
          {...restVideoProps}
          ref={internalRef as Ref<HTMLVideoElement>}
        />
      );
    };

    return (
      <div className={cx('min-w-20', className)} data-ids="CardMedia">
        <div
          className={cx(
            CardMediaVariants({
              aspectRatio,
              responsiveAspectRatio,
              variant,
            }),
            {
              'bg-neutral-1': Component === 'video',
            },
          )}
          {...props}
        >
          {Component === 'video' ? renderVideo() : renderImage()}
          {children && (
            <div className="absolute inset-0 h-full w-full p-2">{children}</div>
          )}
        </div>
      </div>
    );
  },
);
CardMedia.displayName = 'CardMedia';

export default CardMedia;
