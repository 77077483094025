'use client';

import url from 'url';
import { forwardRef, type MouseEvent } from 'react';
import { mdiChevronLeft, mdiChevronRight, mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import { cx } from 'class-variance-authority';

import { type PaginationProps } from './Pagination.props';
import {
  PaginationItemVariants,
  PaginationVariants,
} from './Pagination.variants';

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  (
    {
      boundaryCount = 1,
      className,
      disabled = false,
      hideNextButton = false,
      hidePrevButton = false,
      linkBase,
      linkElement: LinkElement = 'a',
      linkParameter = 'page',
      onChange,
      page = 1,
      siblingCount = 1,
      totalPages = 1,
      ...props
    },
    ref,
  ) => {
    const range = (start: number, end: number) => {
      const length = end - start + 1;
      return Array.from({ length }, (_, i) => start + i);
    };

    const startPages = range(1, Math.min(boundaryCount, totalPages));
    const endPages = range(
      Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
      totalPages,
    );

    const siblingsStart = Math.max(
      Math.min(
        // Raw starting value
        page - siblingCount,

        // Lower boundary when `page` is high
        totalPages - boundaryCount - siblingCount * 2 - 1,
      ),

      // Greater than `startPages`
      boundaryCount + 2,
    );

    const siblingsEnd = Math.min(
      Math.max(
        // Raw ending value
        page + siblingCount,

        // Upper boundary when `page` is low
        boundaryCount + siblingCount * 2 + 2,
      ),

      // Less than `endPages`
      totalPages - boundaryCount - 1,
    );

    const items = [
      ...(hidePrevButton ? [] : ['previous']),
      ...startPages,

      // Start ellipsis
      ...(siblingsStart > boundaryCount + 2
        ? ['ellipsis']
        : boundaryCount + 1 < totalPages - boundaryCount
          ? [boundaryCount + 1]
          : []),

      // Sibling pages
      ...range(siblingsStart, siblingsEnd),

      // End ellipsis
      ...(siblingsEnd < totalPages - boundaryCount - 1
        ? ['ellipsis']
        : totalPages - boundaryCount > boundaryCount
          ? [totalPages - boundaryCount]
          : []),

      ...endPages,
      ...(hideNextButton ? [] : ['next']),
    ];

    const generateUrl = (item: string | number) => {
      const { pathname, query } = url.parse(linkBase || '/', true);

      if (typeof item === 'number') {
        query[linkParameter] = item.toString();
      } else {
        const valueModifier = item === 'next' ? 1 : -1;
        query[linkParameter] = (page + valueModifier).toString();
      }

      return url.format({
        pathname,
        query,
      });
    };

    const generateAriaLabel = (item: string | number) => {
      if (typeof item === 'number') {
        return `Go to page ${item}`;
      }

      if (item === 'next' || item === 'previous') {
        return `Go to ${item} page`;
      }
    };

    const handleClick = (event: MouseEvent, value: string | number) => {
      if (typeof value !== 'number') {
        const valueModifier = value === 'next' ? 1 : -1;
        return onChange?.(event, page + valueModifier);
      }
      onChange?.(event, value);
    };

    const renderItemContent = (item: string | number) => {
      if (typeof item === 'number') {
        return item;
      }

      const iconMap = {
        ellipsis: mdiDotsHorizontal,
        next: mdiChevronRight,
        previous: mdiChevronLeft,
      } as { [key: string]: string };

      return <Icon path={iconMap[item]} size={1} />;
    };

    const determineDisabledState = (item: string | number) => {
      if (disabled) {
        return true;
      }

      if (typeof item !== 'number') {
        if (item === 'ellipsis') {
          return false;
        } else if (item === 'next') {
          return page >= totalPages;
        } else {
          return page <= 1;
        }
      }
    };

    const renderPageList = () => {
      return items.map((item, i) => {
        const isDisabled = determineDisabledState(item);
        const isSelected = typeof item === 'number' && item === page;

        const { className, href, key, ...rest } = {
          'aria-current': isSelected ? true : undefined,
          children: renderItemContent(item),
          className: PaginationItemVariants({
            disabled: isDisabled,
            selected: isSelected,
          }),
          href: generateUrl(item),
          key: `${item}-${i}`,
        };

        if (isDisabled) {
          return <li className={className} key={key} {...rest} />;
        }

        if (item === 'ellipsis') {
          return (
            <li
              className={cx(className, 'pointer-events-none')}
              key={key}
              role="presentation"
              {...rest}
            />
          );
        }

        if (!linkBase) {
          return (
            <li key={key}>
              <button
                aria-label={generateAriaLabel(item)}
                className={className}
                onClick={(event) => handleClick(event, item)}
                {...rest}
              />
            </li>
          );
        }

        return (
          <li key={key}>
            <LinkElement
              aria-label={generateAriaLabel(item)}
              className={className}
              href={href}
              onClick={(event) => handleClick(event, item)}
              {...rest}
            />
          </li>
        );
      });
    };

    return (
      <nav
        aria-disabled={disabled}
        aria-label="pagination navigation"
        className={PaginationVariants({ className, disabled })}
        {...props}
        data-ids="Pagination"
        ref={ref}
      >
        <ul>{renderPageList()}</ul>
      </nav>
    );
  },
);
Pagination.displayName = 'Pagination';

export default Pagination;
