'use client';

import { forwardRef } from 'react';
import { Root } from '@radix-ui/react-radio-group';

import { RadioGroupContext } from './RadioGroup.context';
import { type RadioGroupProps } from './RadioGroup.props';
import { RadioGroupVariants } from './RadioGroup.variants';

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      children,
      className,
      colorScheme = 'neutral',
      disableAnimation = false,
      loop = true,
      orientation = 'vertical',
      ...props
    },
    ref,
  ) => {
    return (
      <RadioGroupContext.Provider value={{ colorScheme, disableAnimation }}>
        <Root
          className={RadioGroupVariants({
            className,
            colorScheme,
            orientation,
          })}
          loop={loop}
          {...props}
          data-ids="RadioGroup"
          ref={ref}
        >
          {children}
        </Root>
      </RadioGroupContext.Provider>
    );
  },
);
RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
