'use client';

import { forwardRef } from 'react';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';

import { useCarousel } from '../Carousel.context';
import { type CarouselNextProps } from './CarouselNext.props';
import { CarouselNextVariants } from './CarouselNext.variants';

export const CarouselNext = forwardRef<HTMLButtonElement, CarouselNextProps>(
  (
    {
      children = <Icon path={mdiChevronRight} />,
      className,
      title = 'Next slide',
      ...props
    },
    ref,
  ) => {
    const { canScrollNext, scrollNext, variant } = useCarousel();

    if (!canScrollNext) {
      return;
    }

    return (
      <button
        className={className || CarouselNextVariants({ variant })}
        title={title}
        {...props}
        data-ids="CarouselNext"
        onClick={scrollNext}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);
CarouselNext.displayName = 'CarouselNext';

export default CarouselNext;
