'use client';

import { forwardRef } from 'react';
import {
  Content,
  Portal,
  type DropdownMenuPortalProps,
} from '@radix-ui/react-dropdown-menu';

import ConditionalWrapper from '@/src/utils/ConditionalWrapper';

import { type MenuContentProps } from './MenuContent.props';
import { MenuContentVariants } from './MenuContent.variants';

export const MenuContent = forwardRef<HTMLDivElement, MenuContentProps>(
  (
    {
      align = 'center',
      alignOffset = 0,
      avoidCollisions = true,
      children,
      className,
      collapsePadding = false,
      container,
      hideWhenDetached = false,
      loop = false,
      side = 'bottom',
      sideOffset = 4,
      theme = 'auto',
      ...props
    },
    ref,
  ) => {
    return (
      <ConditionalWrapper
        condition={container !== false}
        wrapper={(content) => (
          <Portal container={container as DropdownMenuPortalProps['container']}>
            {content}
          </Portal>
        )}
      >
        <Content
          align={align}
          alignOffset={alignOffset}
          avoidCollisions={avoidCollisions}
          className={MenuContentVariants({ className, collapsePadding, theme })}
          hideWhenDetached={hideWhenDetached}
          loop={loop}
          side={side}
          sideOffset={sideOffset}
          {...props}
          data-ids="MenuContent"
          ref={ref}
        >
          {children}
        </Content>
      </ConditionalWrapper>
    );
  },
);
MenuContent.displayName = 'MenuContent';

export default MenuContent;
