'use client';

import { forwardRef } from 'react';
import { Item } from '@radix-ui/react-dropdown-menu';
import { cx } from 'class-variance-authority';

import Typography from '@/src/components/Typography';

import { type MenuItemProps } from './MenuItem.props';
import { MenuItemVariants } from './MenuItem.variants';

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  (
    {
      as,
      children,
      className,
      disabled = false,
      endAdornment,
      href,
      inset = false,
      startAdornment,
      target,
      truncate = false,
      typographyVariant = 'caption1',
      ...props
    },
    ref,
  ) => {
    const renderStartAdornment = () => {
      if (startAdornment) {
        if (typeof startAdornment === 'string') {
          return (
            <Typography
              as="div"
              className="pr-2"
              disableColorScheme
              variant="caption1"
            >
              {startAdornment}
            </Typography>
          );
        }

        return <div className="self-start pr-2">{startAdornment}</div>;
      }

      if (inset) {
        return (
          <div
            className={cx({
              'pr-8': inset === true,
            })}
            style={{
              // Inset style is manually applied because Tailwind classnames cannot be dynamically created.
              // See: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
              width: typeof inset === 'number' ? `${inset}rem` : undefined,
            }}
          />
        );
      }
    };

    const renderEndAdornment = () => {
      if (!endAdornment) {
        return null;
      }

      if (typeof endAdornment === 'string') {
        return (
          <Typography
            as="div"
            className="pl-2"
            disableColorScheme
            variant="caption1"
          >
            {endAdornment}
          </Typography>
        );
      }

      return <div className="self-start pl-2">{endAdornment}</div>;
    };

    const Component = as ? as : href ? 'a' : 'div';

    return (
      <Item
        asChild
        className={MenuItemVariants({ className })}
        disabled={disabled}
        {...props}
        data-ids="MenuItem"
        ref={ref}
      >
        <Component href={href} target={target}>
          {renderStartAdornment()}
          <Typography
            as="div"
            className="flex-1"
            disableColorScheme
            maxLines={truncate ? 1 : undefined}
            variant={typographyVariant}
          >
            {children}
          </Typography>
          {renderEndAdornment()}
        </Component>
      </Item>
    );
  },
);
MenuItem.displayName = 'MenuItem';

export default MenuItem;
