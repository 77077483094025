'use client';

import { createContext, useContext } from 'react';

import { type TabsContextProps } from './Tabs.props';

export const TabsContext = createContext<TabsContextProps | null>(null);

export const useTabs = () => {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('useTabs must be used within a <Tabs /> element');
  }

  return context;
};
