'use client';

import { forwardRef } from 'react';

import { type BreadcrumbItemProps } from './BreadcrumbItem.props';
import { BreadcrumbItemVariants } from './BreadcrumbItem.variants';

export const BreadcrumbItem = forwardRef<HTMLLIElement, BreadcrumbItemProps>(
  ({ children, className, currentPage = false, ...props }, ref) => {
    return (
      <li
        aria-current={currentPage ? 'page' : undefined}
        className={BreadcrumbItemVariants({ className, currentPage })}
        {...props}
        data-ids="BreadcrumbItem"
        ref={ref}
      >
        <span>{children}</span>
      </li>
    );
  },
);
BreadcrumbItem.displayName = 'BreadcrumbItem';

export default BreadcrumbItem;
