'use client';

import { Trigger } from '@radix-ui/react-dropdown-menu';

import { type MenuTriggerProps } from './MenuTrigger.props';

export const MenuTrigger = ({
  asChild = true,
  className,
  ...props
}: MenuTriggerProps) => (
  <Trigger
    asChild={asChild}
    className={className}
    {...props}
    data-ids="MenuTrigger"
  />
);
MenuTrigger.displayName = 'MenuTrigger';

export default MenuTrigger;
