'use client';

import { Label } from '@radix-ui/react-select';

import { MenuHeadingVariants } from '@/src/components/Menu/MenuHeading/MenuHeading.variants';
import Typography from '@/src/components/Typography';

import { type SelectHeadingProps } from './SelectHeading.props';

export const SelectHeading = ({
  children,
  className,
  truncate = false,
  typographyVariant = 'caption2',
  ...props
}: SelectHeadingProps) => {
  return (
    <Label
      className={MenuHeadingVariants({ className })}
      {...props}
      data-ids="SelectHeading"
    >
      <Typography
        disableColorScheme
        maxLines={truncate ? 1 : undefined}
        variant={typographyVariant}
      >
        {children}
      </Typography>
    </Label>
  );
};
SelectHeading.displayName = 'SelectHeading';

export default SelectHeading;
