'use client';

import { forwardRef, useEffect } from 'react';
import { Trigger } from '@radix-ui/react-tabs';

import Typography from '../../Typography';
import { useTabs } from '../Tabs.context';
import { type TabsItemProps } from './TabsItem.props';
import { TabsItemVariants } from './TabsItem.variants';

export const TabsItem = forwardRef<HTMLButtonElement, TabsItemProps>(
  ({ children, className, disabled = false, value, ...props }, ref) => {
    const { registerTab, tabSize, unregisterTab, valuePrefix } = useTabs();
    const tabItemValue = `${valuePrefix}-${value}`;

    useEffect(() => {
      registerTab(tabItemValue);
      return () => unregisterTab(tabItemValue);
    }, []);

    return (
      <Trigger
        asChild
        data-ids="TabsItem"
        disabled={disabled}
        ref={ref}
        value={tabItemValue}
      >
        <Typography
          as="button"
          className={TabsItemVariants({
            className,
            disabled,
            tabSize,
          })}
          {...props}
          disableColorScheme
          variant="button2"
        >
          {children}
        </Typography>
      </Trigger>
    );
  },
);
TabsItem.displayName = 'TabsItem';

export default TabsItem;
