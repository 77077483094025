'use client';

import { forwardRef } from 'react';
import { Item } from '@radix-ui/react-accordion';

import { type AccordionItemProps } from './AccordionItem.props';
import { AccordionItemVariants } from './AccordionItem.variants';

export const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <Item
        className={AccordionItemVariants({ className })}
        {...props}
        data-ids="AccordionItem"
        ref={ref}
      />
    );
  },
);
AccordionItem.displayName = 'AccordionItem';

export default AccordionItem;
