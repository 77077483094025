'use client';

import { forwardRef, useState } from 'react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import IconButton from '@/src/components/IconButton';
import Modal from '@/src/components/Modal';

import { DialogContext } from './Dialog.context';
import { type DialogProps } from './Dialog.props';
import { DialogVariants } from './Dialog.variants';

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      children,
      className,
      closeOnActionOnly = false,
      disableAnimation = false,
      onClose = () => {},
      showCloseButton = true,
      theme = 'auto',
      ...props
    },
    ref,
  ) => {
    const [labelledBy, setLabelledBy] = useState<string | undefined>();
    const [describedBy, setDescribedBy] = useState<string | undefined>();

    const handleClose = (
      event: Event | KeyboardEvent | MouseEvent,
      reason: string,
    ) => {
      if (
        closeOnActionOnly &&
        ['backdropClick', 'escapeKeyDown'].includes(reason)
      ) {
        return;
      }

      onClose?.(event);
    };

    return (
      <DialogContext.Provider
        value={{ setDescribedBy, setLabelledBy, showCloseButton }}
      >
        <Modal
          aria-describedby={describedBy}
          aria-label={
            !labelledBy ? (closeOnActionOnly ? 'Alert' : 'Dialog') : undefined
          }
          aria-labelledby={labelledBy}
          className={DialogVariants({ className, disableAnimation, theme })}
          onClose={handleClose}
          {...props}
          data-ids="Dialog"
          ref={ref}
          role={closeOnActionOnly ? 'alertdialog' : undefined}
        >
          {showCloseButton && (
            <IconButton
              className="absolute right-3 top-4 text-neutral-1 dark:text-neutral-8"
              colorScheme="transparent"
              onClick={onClose}
              ripple={false}
              rounded
            >
              <Icon className="size-[24px]" path={mdiClose} />
            </IconButton>
          )}
          {children}
        </Modal>
      </DialogContext.Provider>
    );
  },
);
Dialog.displayName = 'Dialog';

export default Dialog;
