'use client';

import { forwardRef } from 'react';
import { Item, ItemText } from '@radix-ui/react-select';
import { cx } from 'class-variance-authority';

import { MenuItemVariants } from '@/src/components/Menu/MenuItem/MenuItem.variants';
import Typography from '@/src/components/Typography';

import { type SelectItemProps } from './SelectItem.props';

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  (
    {
      children,
      className,
      disabled = false,
      truncate = false,
      typographyVariant = 'caption1',
      value,
      ...props
    },
    ref,
  ) => {
    return (
      <Item
        asChild
        className={cx(
          MenuItemVariants({ className }),
          '[&[data-state=checked][data-highlighted]]:bg-neutral-5/50',
          '[&[data-state=checked][data-highlighted]]:dark:bg-neutral-4/50',
          'dark:data-[state=checked]:bg-neutral-3',
          'data-[state=checked]:bg-neutral-6',
        )}
        disabled={disabled}
        value={value}
        {...props}
        data-ids="SelectItem"
        ref={ref}
      >
        <Typography
          maxLines={truncate ? 1 : undefined}
          variant={typographyVariant}
        >
          <ItemText>{children}</ItemText>
        </Typography>
      </Item>
    );
  },
);
SelectItem.displayName = 'SelectItem';

export default SelectItem;
