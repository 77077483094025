'use client';

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import { cx } from 'class-variance-authority';

import {
  Menu,
  MenuContent,
  MenuItem,
  MenuTrigger,
} from '@/src/components/Menu';
import Typography from '@/src/components/Typography';

import { type BreadcrumbsProps } from './Breadcrumbs.props';
import { BreadcrumbsVariants } from './Breadcrumbs.variants';

export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(
  (
    {
      'aria-label': ariaLabel = 'breadcrumbs',
      children,
      className,
      collapse: collapsable = true,
      separator = '/',
      typographyProps = {
        disableColorScheme: true,
        variant: 'caption2',
      },
      ...props
    },
    forwardedRef,
  ) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const isCollapsedRef = useRef(isCollapsed);
    const breadcrumbRef = useRef<HTMLOListElement>(null);
    const breadcrumbExpandedWidthRef = useRef<number | null>(null);
    const internalRef = useRef<HTMLElement>(null);
    useImperativeHandle(forwardedRef, () => internalRef.current!, []);

    const handleCollapse = () => {
      if (!collapsable || !internalRef.current || !breadcrumbRef.current) {
        return;
      }

      const { width: parentWidth } =
        internalRef.current.getBoundingClientRect();
      const { width: crumbsWidth } =
        breadcrumbRef.current.getBoundingClientRect();

      // While collapsed, if at any point the original full width of the
      // breadcrumbs fits within the available space in the parent, expand it.
      if (
        isCollapsedRef.current &&
        parentWidth > (breadcrumbExpandedWidthRef?.current || 0)
      ) {
        breadcrumbExpandedWidthRef.current = null;
        return setIsCollapsed(false);
      }

      // While expanded, if the space of the crumbs exceeds the available since
      // in the parent, collapse it.
      if (!isCollapsedRef.current && crumbsWidth > parentWidth) {
        if (!breadcrumbExpandedWidthRef.current) {
          breadcrumbExpandedWidthRef.current = crumbsWidth;
        }
        return setIsCollapsed(true);
      }
    };

    // We're additionally storing the state in a ref to avoid mounting
    // and unmounting our event listeners to increase performance.
    useEffect(() => {
      isCollapsedRef.current = isCollapsed;
    }, [isCollapsed]);

    useLayoutEffect(() => {
      const waitForFonts = async () => {
        await document.fonts.ready;
        handleCollapse();
        setIsLoading(false);
      };
      waitForFonts();
      window.addEventListener('resize', handleCollapse);
      return () => window.removeEventListener('resize', handleCollapse);
    }, [isCollapsed]);

    const BreadcrumbSeparator = () => (
      <li aria-hidden="true" role="separator">
        {separator}
      </li>
    );

    const renderBreadcrumbItems = () => {
      const breadcrumbItems = [
        ...(Array.isArray(children) ? children : [children]),
      ];

      if (collapsable && isCollapsed) {
        const firstItem = breadcrumbItems.shift();
        const lastItem = breadcrumbItems.pop();
        return [
          firstItem,
          <BreadcrumbSeparator key="separator-first" />,
          <Menu key="overflow-menu">
            <MenuTrigger>
              <li className="flex justify-center">
                <button aria-label="See all breadcrumbs">
                  <Icon path={mdiDotsHorizontal} size={0.75} />
                </button>
              </li>
            </MenuTrigger>
            <MenuContent>
              {breadcrumbItems.map((item, i) => (
                <MenuItem
                  as="ul"
                  className="[&_a]:hover:no-underline"
                  key={`menu-${i}`}
                >
                  {item}
                </MenuItem>
              ))}
            </MenuContent>
          </Menu>,
          <BreadcrumbSeparator key="separator-last" />,
          lastItem,
        ];
      }

      return breadcrumbItems.flatMap((item, index, arr) => {
        return arr.length - 1 !== index
          ? [item, <BreadcrumbSeparator key={`separator-${index}`} />]
          : item;
      });
    };

    return (
      <nav
        aria-label={ariaLabel}
        className={cx('w-full leading-none', {
          invisible: isLoading,
        })}
        {...props}
        data-ids="Breadcrumbs"
        ref={internalRef}
      >
        <Typography
          {...typographyProps}
          as="ol"
          className={BreadcrumbsVariants({
            className,
            collapse: (collapsable && isCollapsed) || !collapsable,
          })}
          ref={breadcrumbRef}
          role="list"
        >
          {renderBreadcrumbItems()}
        </Typography>
      </nav>
    );
  },
);
Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
