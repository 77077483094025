'use client';

import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
} from 'react';
import { mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { cx } from 'class-variance-authority';
import { useHotkeys } from 'react-hotkeys-hook';

import IconButton from '@/src/components/IconButton';
import TextField from '@/src/components/TextField';
import useBreakpoint from '@/src/utils/useBreakpoint';

import { type GlobalHeaderActionsProps } from './GlobalHeaderActions.props';
import { GlobalHeaderActionsSearchVariants } from './GlobalHeaderActions.variants';

export const GlobalHeaderActions = forwardRef<
  HTMLDivElement,
  GlobalHeaderActionsProps
>(
  (
    {
      actionElement,
      brand = 'motortrend',
      className,
      searchAction,
      searchCallback,
      searchName = 'search',
      ...props
    },
    ref,
  ) => {
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const hasSearchValue = searchValue !== '';
    const searchBoxRef = useRef<null | HTMLInputElement>(null);
    const searchContainerRef = useRef<null | HTMLDivElement>(null);
    const actionElementRef = useRef<null | HTMLDivElement>(null);
    const metSmallBp = useBreakpoint('sm');

    // If no search action or callback is provided, we hide the search interface
    const searchEnabled = searchAction || searchCallback;

    useHotkeys('ctrl+k, meta+k', () => searchBoxRef.current?.focus());

    const expandSearchBox = () => {
      setSearchExpanded(true);
      searchBoxRef.current?.focus();
    };

    const collapseSearchBox = (clearContents = false) => {
      if (hasSearchValue && !clearContents) {
        return;
      }

      if (clearContents) {
        setSearchValue('');
      }

      setSearchExpanded(false);
      searchBoxRef.current?.blur();
    };

    const handleSearchDisplay = (event: TransitionEvent) => {
      if (!metSmallBp) {
        return;
      }

      if (actionElementRef.current) {
        if (searchExpanded && event.type === 'transitionstart') {
          actionElementRef.current.style.display = 'none';
        }

        if (!searchExpanded && event.type === 'transitionend') {
          actionElementRef.current.style.display = 'flex';
        }
      }
    };

    useEffect(() => {
      if (searchContainerRef.current) {
        searchContainerRef.current.addEventListener(
          'transitionstart',
          handleSearchDisplay,
        );
        searchContainerRef.current.addEventListener(
          'transitionend',
          handleSearchDisplay,
        );

        return () => {
          searchContainerRef.current?.removeEventListener(
            'transitionstart',
            handleSearchDisplay,
          );
          searchContainerRef.current?.removeEventListener(
            'transitionend',
            handleSearchDisplay,
          );
        };
      }
    }, [searchExpanded]);

    return (
      <div
        className={cx('flex items-center gap-4', className)}
        {...props}
        data-ids="GlobalHeaderActions"
        ref={ref}
      >
        {!!actionElement && (
          <div
            className="flex h-full flex-shrink-0 items-center"
            ref={actionElementRef}
          >
            {actionElement}
          </div>
        )}
        {searchEnabled && (
          <form
            // The default typings for an HTML form don't yet play nicely
            // with Next.js Server Actions.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action={searchAction as any}
            className={cx({
              'absolute left-0 z-10 flex h-full w-full items-center sm:relative sm:w-auto':
                searchExpanded,
            })}
            onSubmit={(e) => {
              if (!searchCallback) {
                return;
              }

              // If a searchAction is defined with a searchCallback
              // don't prevent the default behavior. This allows for
              // immediate feedback use-cases from the callback, while
              // still allowing a hard action to occur.
              if (!searchAction) {
                e.preventDefault();
              }

              searchCallback(searchValue);
            }}
          >
            <TextField
              aria-keyshortcuts="Ctrl+K Meta+K"
              aria-label="Search"
              autoComplete="off"
              className={GlobalHeaderActionsSearchVariants({
                brand,
                searchExpanded,
              })}
              data-expanded={searchExpanded}
              data-testid="header-search"
              endAdornment={
                <IconButton
                  aria-label="Clear Search Box"
                  className={cx('-mr-2', {
                    hidden: !searchExpanded,
                    'sm:hidden': !hasSearchValue,
                  })}
                  colorScheme="neutral-8"
                  compact
                  data-id="global-search"
                  data-parent="global-header"
                  data-testid="header-search-close"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    collapseSearchBox(true);
                  }}
                  rounded
                  size="small"
                  type="button"
                >
                  <Icon path={mdiClose} size={0.9} />
                </IconButton>
              }
              name={searchName}
              onBlur={() => {
                if (metSmallBp) {
                  collapseSearchBox();
                }
              }}
              onChange={(event: ChangeEvent) => {
                const target = event.target as HTMLInputElement;
                setSearchValue(target.value);
              }}
              onFocus={expandSearchBox}
              onKeyDown={(event) => {
                if (event.key === 'Escape') {
                  collapseSearchBox(true);
                }
              }}
              onSubmit={() => {
                window.dispatchEvent(
                  new CustomEvent('form', {
                    bubbles: true,
                    detail: {
                      action: 'search',
                      deprecated: true,
                      location: 'globalHeader',
                      searchValue,
                    },
                  }),
                );
              }}
              parentRef={searchContainerRef}
              placeholder="Search"
              ref={searchBoxRef}
              startAdornment={
                <Icon className="-ml-1" path={mdiMagnify} size={1} />
              }
              value={searchValue}
            />
          </form>
        )}
      </div>
    );
  },
);
GlobalHeaderActions.displayName = 'GlobalHeaderActions';

export default GlobalHeaderActions;
