'use client';

import { useEffect, useState } from 'react';

import tailwindTheme from '../../tailwind/theme';
import generateQueries from './generateQueries';

const useBreakpoint = (
  breakpoint?: keyof (typeof tailwindTheme)['screens'],
): string | boolean => {
  const { screens } = tailwindTheme as { screens: { [key: string]: string } };

  // Breakpoint provided, but not in the config
  if (breakpoint && !screens[breakpoint]) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        `The provided breakpoint '${breakpoint}' is not defined in the Tailwind config. 'useBreakpoint' will always return 'false'.`,
      );
    }
    return false;
  }

  const queriesToWatch = generateQueries(screens, breakpoint);

  const getCurrentBreakpoint = (queries: {
    [key: string]: string;
  }): string | boolean => {
    // Prevent server-side rendering issues
    if (typeof window === 'object') {
      const currentBreakpoint = Object.keys(queries).find(
        (breakpoint) => window.matchMedia(queries[breakpoint]).matches,
      );
      return currentBreakpoint ?? false;
    }
    return 'unavailable';
  };

  const [matches, setMatches] = useState<string | boolean>(
    getCurrentBreakpoint(queriesToWatch),
  );

  const handleChange = () => setMatches(getCurrentBreakpoint(queriesToWatch));

  useEffect(() => {
    const unsubscribers = Object.keys(queriesToWatch).map((breakpoint) => {
      const matchQuery = window.matchMedia(queriesToWatch[breakpoint]);
      matchQuery.addEventListener('change', handleChange);
      return () => matchQuery.removeEventListener('change', handleChange);
    });

    return () => unsubscribers.forEach((unsubscriber) => unsubscriber());
  }, [breakpoint]);

  return matches;
};

export default useBreakpoint;
