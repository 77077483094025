'use client';

import { forwardRef, useRef, useState } from 'react';
import { Range, Root, Thumb, Track } from '@radix-ui/react-slider';

import Tooltip from '@/src/components/Tooltip';
import ConditionalWrapper from '@/src/utils/ConditionalWrapper';

import { type SliderProps } from './Slider.props';
import {
  SliderRangeVariants,
  SliderThumbVariants,
  SliderTrackVariants,
  SliderVariants,
} from './Slider.variants';
import SliderMarks from './SliderMarks';

const MAX_VALUES = 2;

export const Slider = forwardRef<HTMLDivElement, SliderProps>(
  (
    {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      className,
      colorScheme = 'primary',
      defaultValue,
      disableSwap = false,
      disabled = false,
      hideActiveTicks = false,
      inverted = false,
      marks,
      marksTypographyProps,
      max = 100,
      min = 0,
      minStepsBetweenThumbs = 0,
      onValueChange,
      orientation = 'horizontal',
      showValueTooltip = false,
      size = 'medium',
      step = 1,
      track = true,
      value,
      valueTooltipFormat = (value: number) => value.toString(),
      valueTooltipSide,
      ...props
    },
    ref,
  ) => {
    const isControlled = typeof onValueChange === 'function';
    const draggingRef = useRef<number | null>(null);
    const thumbRefs = useRef<(HTMLSpanElement | null)[]>([]);

    const [internalValue, setInternalValue] = useState<number[]>(
      (isControlled
        ? value?.slice(0, MAX_VALUES)
        : defaultValue?.slice(0, MAX_VALUES)) || [],
    );
    const [tooltipVisible, setTooltipVisible] = useState<false | number>(false);
    const [isDragging, setIsDragging] = useState<number | null>(null);

    const handleValueUpdate = (value: number[]) => {
      onValueChange?.(value);
      setInternalValue(value);
    };

    const handleValueChange = (e: number[]) => {
      // Disabling the swap of two thumbs is not something the Radix primitive currently
      // supports, even though they claim to have met WAI-ARIA specifications for a slider.
      // See: <https://github.com/radix-ui/primitives/issues/2247>
      //
      // The following bit of code is a workaround to support disabling swapping of two
      // thumbs. Hopefully, in a future update of the Radix primitive, we'll be able
      // to remove this code and use functionality exposed directly by the primitive.
      if (disableSwap) {
        if (draggingRef.current !== null) {
          if (internalValue.length === 2) {
            // Get the opposite thumb so we can determine how to calculate `minStepsBetweenThumbs`
            const oppositeThumbIndex = 1 - draggingRef.current;
            const valueModifier = oppositeThumbIndex === 0 ? 1 : -1;

            // Calculate the max/min value the dragging thumb can possibly have.
            const valueLimit =
              internalValue[oppositeThumbIndex] +
              valueModifier * minStepsBetweenThumbs;
            const clampedValue = Math[
              draggingRef.current === 0 ? 'min' : 'max'
            ](e[draggingRef.current], valueLimit);

            // The default Radix primitive behavior moves focus after a thumb is dragged
            // over another one. While we are preventing this from happening internally,
            // their behavior still exists, so we just quickly set focus back to the
            // thumb being dragged.
            thumbRefs.current[draggingRef.current]?.focus();

            return handleValueUpdate(
              internalValue.toSpliced(draggingRef.current, 1, clampedValue),
            );
          }

          return handleValueUpdate(
            internalValue.toSpliced(
              draggingRef.current,
              1,
              e[draggingRef.current],
            ),
          );
        }
      }

      handleValueUpdate(e);
    };

    const showTooltip = (value: number) => {
      if (showValueTooltip === 'auto') {
        setTooltipVisible(value);
      }
    };

    const hideTooltip = () => {
      setTooltipVisible(false);
    };

    return (
      <Root
        className={SliderVariants({ className, disabled, orientation, size })}
        defaultValue={!isControlled ? defaultValue : undefined}
        disabled={disabled}
        inverted={inverted}
        max={max}
        min={min}
        minStepsBetweenThumbs={minStepsBetweenThumbs}
        onValueChange={handleValueChange}
        orientation={orientation}
        step={step}
        value={isControlled ? value : internalValue}
        {...props}
        data-ids="Slider"
        ref={ref}
      >
        <Track className={SliderTrackVariants({ orientation })}>
          {track && (
            <Range
              className={SliderRangeVariants({
                colorScheme,
                orientation,
              })}
            />
          )}
          <SliderMarks
            hideActiveTicks={hideActiveTicks}
            inverted={inverted}
            marks={marks}
            max={max}
            min={min}
            orientation={orientation}
            setValue={handleValueChange}
            size={size}
            step={step}
            track={track}
            typographyProps={marksTypographyProps}
            value={internalValue}
          />
        </Track>
        {internalValue?.map((_, i) => (
          <ConditionalWrapper
            condition={!!showValueTooltip}
            key={`thumb-${i}`}
            wrapper={(children) => (
              <Tooltip
                content={valueTooltipFormat(internalValue[i])}
                open={showValueTooltip === true || tooltipVisible === i}
                side={
                  valueTooltipSide || orientation === 'horizontal'
                    ? 'top'
                    : 'right'
                }
              >
                {children}
              </Tooltip>
            )}
          >
            <Thumb
              aria-label={ariaLabel}
              aria-labelledby={ariaLabelledBy}
              className={SliderThumbVariants({
                colorScheme,
                disabled,
                dragging: isDragging === i,
              })}
              onBlurCapture={hideTooltip}
              onFocusCapture={() => showTooltip(i)}
              onPointerDown={() => {
                draggingRef.current = i;
                setIsDragging(i);
              }}
              onPointerEnter={() => showTooltip(i)}
              onPointerLeave={hideTooltip}
              onPointerUp={() => {
                setIsDragging(null);
              }}
              ref={(element) => (thumbRefs.current[i] = element)}
            />
          </ConditionalWrapper>
        ))}
      </Root>
    );
  },
);
Slider.displayName = 'Slider';

export default Slider;
