'use client';

import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  type RefCallback,
} from 'react';

import tailwindTheme from '../../tailwind/theme';
import generateBreakpoints from './generateBreakpoints';

type ContainerBreakpointResult<T> = {
  ref: RefCallback<T>;
  result: boolean | string;
};

function useContainerBreakpoint<T extends HTMLElement>(
  breakpoint?: keyof (typeof tailwindTheme)['containers'],
): ContainerBreakpointResult<T> {
  const observerRef = useRef<ResizeObserver | null>(null);
  const elementRef = useRef<HTMLElement | null>(null);

  const { containers } = tailwindTheme as {
    containers: { [key: string]: string };
  };

  // Breakpoint provided, but not in the config
  if (breakpoint && !containers[breakpoint]) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        `The provided breakpoint '${breakpoint}' is not defined in the Tailwind config. 'useContainerBreakpoint' will always return 'false'.`,
      );
    }
    return {
      ref: (node: HTMLElement | null) => {
        elementRef.current = node;
      },
      result: false,
    };
  }

  const [activeBreakpoint, setActiveBreakpoint] =
    useState<string>('unavailable');

  const breakpointsToWatch = generateBreakpoints(containers);

  const matchBreakpoint = useCallback(
    (prevActive: string, width: number) => {
      let currentActive;
      for (const [key, [min, max]] of Object.entries(breakpointsToWatch)) {
        if (width >= min) {
          if (max === undefined) {
            currentActive = key;
            break;
          } else if (width <= max) {
            currentActive = key;
            break;
          }
        }
      }
      return currentActive || prevActive;
    },
    [breakpointsToWatch],
  );

  const handleResize = useCallback(
    ([entry]: ResizeObserverEntry[]) => {
      const width = Math.round(entry.borderBoxSize[0].inlineSize);
      setActiveBreakpoint(matchBreakpoint(activeBreakpoint, width));
    },
    [activeBreakpoint, matchBreakpoint],
  );

  useLayoutEffect(() => {
    if (typeof window !== 'object') {
      return;
    }

    if (!observerRef.current) {
      observerRef.current = new ResizeObserver(handleResize);
    }

    if (elementRef.current) {
      observerRef.current.observe(elementRef.current, {
        box: 'border-box',
      });
    }

    return () => {
      observerRef.current?.disconnect();
      observerRef.current = null;
    };
  }, [handleResize]);

  const assignRef = useCallback((node: T | null) => {
    if (elementRef.current) {
      observerRef.current?.unobserve(elementRef.current);
    }

    elementRef.current = node;
    if (node) {
      observerRef.current?.observe(node);
    }
  }, []);

  const determineResult = () => {
    if (!breakpoint) {
      return activeBreakpoint;
    }

    return (
      parseInt(containers[activeBreakpoint]) >= parseInt(containers[breakpoint])
    );
  };

  return {
    ref: assignRef,
    result: determineResult(),
  };
}

export default useContainerBreakpoint;
