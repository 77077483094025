'use client';

import { forwardRef } from 'react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Header, Trigger } from '@radix-ui/react-accordion';

import Typography from '@/src/components/Typography';

import { type AccordionHeaderProps } from './AccordionHeader.props';
import { AccordionHeaderVariants } from './AccordionHeader.variants';

export const AccordionHeader = forwardRef<
  HTMLButtonElement,
  AccordionHeaderProps
>(
  (
    {
      children,
      className,
      hideIcon = false,
      icon = mdiChevronDown,
      typographyProps,
      ...props
    },
    ref,
  ) => {
    return (
      <Header asChild className="flex" data-ids="AccordionHeader">
        <Typography as="h3" variant="button1" {...typographyProps}>
          <Trigger
            className={AccordionHeaderVariants({ className })}
            {...props}
            ref={ref}
          >
            {children}
            {!hideIcon && (
              <Icon
                className="h-4 w-4 shrink-0 transition-transform duration-200"
                path={icon}
                size={1}
              />
            )}
          </Trigger>
        </Typography>
      </Header>
    );
  },
);
AccordionHeader.displayName = 'AccordionHeader';

export default AccordionHeader;
