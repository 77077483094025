'use client';

import { forwardRef, useContext, useEffect, useId } from 'react';

import Typography from '@/src/components/Typography';

import { DialogContext } from '../Dialog.context';
import { type DialogContentProps } from './DialogContent.props';
import { DialogContentVariants } from './DialogContent.variants';

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (
    {
      children,
      className,
      collapsePadding = false,
      variant = 'body2',
      ...props
    },
    ref,
  ) => {
    const context = useContext(DialogContext);
    if (!context) {
      throw new Error('DialogContent must be used within Dialog.');
    }

    const { setDescribedBy } = context;
    const descId = useId();
    const hasSingleChild = !Array.isArray(children);

    useEffect(() => {
      // According to WAI-ARIA patterns for a Dialog, when a dialog
      // includes more than one single semantic item, it is
      // advisable to omit applying the `aria-describedby` attribute.
      if (hasSingleChild) {
        setDescribedBy(descId);
      }
    }, [descId]);

    return (
      <Typography
        as="div"
        className={DialogContentVariants({ className, collapsePadding })}
        id={descId}
        variant={variant}
        {...props}
        data-ids="DialogContent"
        ref={ref}
      >
        {children}
      </Typography>
    );
  },
);
DialogContent.displayName = 'DialogContent';

export default DialogContent;
