'use client';

import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Content } from '@radix-ui/react-tabs';

import useIntersection from '@/src/utils/useIntersection';

import { useTabs } from '../Tabs.context';
import { type TabsContentProps } from './TabsContent.props';
import { TabsContentVariants } from './TabsContent.variants';

export const TabsContent = forwardRef<HTMLDivElement, TabsContentProps>(
  ({ children, className, value, ...props }, forwardedRef) => {
    const internalRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => internalRef.current!, []);
    const { isJumpList, setTabContentVisibility, valuePrefix } = useTabs();
    const tabContentValue = `${valuePrefix}-${value}`;

    // Only run the intersection observer and report on tab content
    // visibility if the Tabs are configured as a jump list.
    if (isJumpList) {
      const threshold = 0;
      const intersection = useIntersection(internalRef, {
        rootMargin: '-66px',
        threshold,
      });

      useEffect(() => {
        if (isJumpList && internalRef.current) {
          // Determine the max ratio of visibility based on the viewport height
          const maxRatio =
            window.innerHeight /
            internalRef.current.getBoundingClientRect().height;
          const measuredRatio =
            maxRatio < threshold ? 0.99 * maxRatio : threshold;

          const isVisible =
            intersection?.isIntersecting &&
            intersection.intersectionRatio >= measuredRatio;
          setTabContentVisibility(tabContentValue, isVisible || false);
        }
      }, [intersection?.intersectionRatio]);
    }

    return (
      <Content
        className={TabsContentVariants({ className, jumpList: isJumpList })}
        data-value={tabContentValue}
        forceMount
        value={tabContentValue}
        {...props}
        data-ids="TabsContent"
        ref={internalRef}
      >
        {children}
      </Content>
    );
  },
);
TabsContent.displayName = 'TabsContent';

export default TabsContent;
