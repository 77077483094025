'use client';

import { forwardRef, useRef, useState, type ChangeEvent } from 'react';
import { cx } from 'class-variance-authority';
import { motion } from 'framer-motion';

import Button from '@/src/components/Button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from '@/src/components/Dialog';
import TextField from '@/src/components/TextField';
import Typography from '@/src/components/Typography';

import { type ReportIssueDialogProps } from './ReportIssueDialog.props';
import { ReportIssueDialogVariants } from './ReportIssueDialog.variants';

export const ReportIssueDialog = forwardRef<
  HTMLDivElement,
  ReportIssueDialogProps
>(
  (
    {
      action = () => {},
      className,
      'data-id': dataId = 'report-issue',
      'data-parent': dataParent,
      promptText = 'Please describe the issue you are experiencing.',
      title = 'Report Issue',
      trigger = 'button',
      triggerText = 'Report Issue',
      ...props
    },
    ref,
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleAction = () => {
      if (!data || data === '') {
        textAreaRef.current?.focus();
        return setError(true);
      }

      try {
        action(data);
        setSuccess(true);
      } catch (e) {
        console.error(e);
        setError(true);
      }
    };

    const handleOpen = () => {
      setData('');
      setError(false);
      setSuccess(false);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const renderSuccessIcon = () => {
      const draw = {
        hidden: { opacity: 0, pathLength: 0 },
        visible: (i: number) => {
          const delay = i / 3;
          return {
            opacity: 1,
            pathLength: 1,
            transition: {
              opacity: { delay, duration: 0.01 },
              pathLength: { bounce: 0, delay, duration: 1.5, type: 'spring' },
            },
          };
        },
      };

      return (
        <motion.svg
          animate="visible"
          className="size-16 text-success-2"
          fill="transparent"
          height="24"
          initial="hidden"
          stroke="currentColor"
          viewBox="0 0 24 24"
          width="24"
        >
          <motion.circle
            custom={0}
            cx="12"
            cy="12"
            r="10"
            strokeLinecap="round"
            strokeWidth="1.25"
            variants={draw}
          />
          <motion.path
            className="origin-center"
            custom={1}
            // This is a custom stroke-based path version of the MDI icon `mdiCheck`
            d="M4.2,12.8l4.8,4.8L20.3,6.3"
            height="20"
            strokeWidth="2"
            transform="scale(0.7)"
            variants={draw}
            width="20"
          />
        </motion.svg>
      );
    };

    return (
      <div
        className={ReportIssueDialogVariants({ className, trigger })}
        {...props}
        data-ids="ReportIssueDialog"
        ref={ref}
      >
        {trigger === 'button' ? (
          <Button
            className="max-h-8 px-4 py-2 sm:max-h-10 sm:px-6 sm:py-3 [&>p]:!typography-button2 [&>p]:sm:!typography-button1"
            colorScheme="neutral-3"
            data-id={dataId}
            data-parent={dataParent}
            ignoreTheme
            onClick={handleOpen}
          >
            {triggerText}
          </Button>
        ) : (
          <button
            className="cursor-pointer underline"
            data-id={dataId}
            data-parent={dataParent}
            onClick={handleOpen}
          >
            {triggerText}
          </button>
        )}
        <Dialog closeOnActionOnly onClose={handleClose} open={open}>
          <DialogTitle
            className={cx('transition-opacity', {
              'pointer-events-none opacity-0': success,
            })}
          >
            {title}
          </DialogTitle>
          <DialogContent className="overflow-hidden" collapsePadding>
            {success && (
              <div className="absolute -mt-0 flex w-full flex-col items-center duration-500 animate-in slide-in-from-bottom-8 sm:-mt-8">
                {renderSuccessIcon()}
                <Typography className="mt-2" gutterBottom variant="h4">
                  Success
                </Typography>
                <Typography align="center" className="px-4" variant="body2">
                  Your message has been received. Thank you for your feedback.
                </Typography>
              </div>
            )}
            <div
              className={cx(
                'mx-6 flex flex-col gap-4 pb-2 transition-opacity sm:gap-6',
                {
                  'pointer-events-none opacity-0': success,
                },
              )}
            >
              <Typography
                className={cx({ '!text-error-2 dark:!text-error-3': error })}
                variant="body2"
              >
                {promptText}
              </Typography>
              <TextField
                as="textarea"
                autoFocus
                error={error}
                maxRows={5}
                minRows={5}
                name="data"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setData(e.target.value)
                }
                ref={textAreaRef}
                value={data}
              />
            </div>
          </DialogContent>
          <DialogFooter
            className={cx('transition-opacity', {
              'pointer-events-none opacity-0': success,
            })}
          >
            <Button onClick={handleAction}>Send</Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  },
);
ReportIssueDialog.displayName = 'ReportIssueDialog';

export default ReportIssueDialog;
