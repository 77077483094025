'use client';

import {
  forwardRef,
  useId,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  type ChangeEvent,
} from 'react';
import { cx } from 'class-variance-authority';
import { type PolyRefFunction } from 'react-polymorphed';

import InputLabel from '@/src/components/InputLabel';

import { type TextFieldProps } from './TextField.props';
import { TextFieldVariants } from './TextField.variants';

const forwardPolymorphicRef = forwardRef as PolyRefFunction;

export const TextField = forwardPolymorphicRef<'input', TextFieldProps>(
  (
    {
      as: Component = 'input',
      autoFocus = false,
      className,
      compact = false,
      disabled = false,
      endAdornment,
      error = false,
      id,
      label,
      maxRows,
      minRows = 1,
      name,
      onBlur,
      onChange,
      onFocus,
      parentRef,
      required = false,
      startAdornment,
      ...props
    },
    forwardedRef,
  ) => {
    const componentId = id || useId();
    const internalRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const initialTextAreaRowHeight = useRef(0);
    const maxTextAreaHeight = useRef<number | null>(null);
    useImperativeHandle(forwardedRef, () => internalRef.current!, []);

    useLayoutEffect(() => {
      if (autoFocus) {
        internalRef.current?.focus();
      }

      if (internalRef.current) {
        initialTextAreaRowHeight.current =
          internalRef.current.offsetHeight / minRows;

        if (maxRows) {
          maxTextAreaHeight.current =
            initialTextAreaRowHeight.current * maxRows;
        }

        resizeTextArea();
      }
    }, []);

    const handleChange = (
      event: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>,
    ) => {
      resizeTextArea();

      // Pass through any originally supplied change events
      onChange?.(event);
    };

    const resizeTextArea = () => {
      if (Component === 'textarea' && internalRef.current) {
        internalRef.current.style.height = 'inherit';
        const { scrollHeight } = internalRef.current;

        if (maxTextAreaHeight.current) {
          internalRef.current.style.height = `${Math.min(
            scrollHeight,
            // The addition pixel is to avoid a scroll bar on the last
            // row due to very slight rounding errors and is purely cosmetic.
            maxTextAreaHeight.current + 1,
          )}px`;
        } else {
          internalRef.current.style.height = `${scrollHeight}px`;
        }
      }
    };

    const focusInputField = () => {
      if (document.activeElement !== internalRef.current) {
        internalRef.current?.focus();
      }
    };

    return (
      <div
        className={cx('group', className)}
        data-ids="TextField"
        onBlur={onBlur}
        onClick={focusInputField}
        onFocus={onFocus}
        ref={parentRef}
        tabIndex={-1}
      >
        {label && (
          <InputLabel
            className="mb-3 block"
            disabled={disabled}
            error={error}
            htmlFor={componentId}
          >
            {label}
            {required && (
              <span className="ml-1 text-error-2 dark:text-error-3">*</span>
            )}
          </InputLabel>
        )}
        <div
          className={cx(TextFieldVariants({ compact, disabled, error }), {
            'max-h-10': Component === 'input' && !compact,
            'max-h-2': Component === 'input' && compact,
          })}
        >
          {startAdornment && (
            <span
              className={cx('-ml-1 leading-[0]', {
                'pr-1': compact,
                'pr-2': !compact,
              })}
            >
              {startAdornment}
            </span>
          )}
          <Component
            aria-invalid={error}
            className="w-full resize-none text-[1rem] leading-4 scrollbar-dark placeholder-shown:text-ellipsis focus:outline-none"
            disabled={disabled}
            id={componentId}
            name={name}
            onChange={handleChange}
            ref={internalRef}
            required={required}
            rows={Component === 'textarea' ? minRows : undefined}
            {...props}
          />
          {endAdornment && (
            <span
              className={cx('-mr-1 leading-[0]', {
                'pl-1': compact,
                'pl-2': !compact,
              })}
            >
              {endAdornment}
            </span>
          )}
        </div>
      </div>
    );
  },
);
TextField.displayName = 'TextField';

export default TextField;
