'use client';

import { forwardRef, useState } from 'react';
import { mdiCheck, mdiMinus } from '@mdi/js';
import Icon from '@mdi/react';
import { Indicator, Root, type CheckedState } from '@radix-ui/react-checkbox';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import { type CheckboxProps } from './Checkbox.props';
import { CheckboxVariants } from './Checkbox.variants';

const Check = ({
  checked,
  disableAnimation,
  indeterminate,
}: {
  checked?: CheckedState;
  disableAnimation?: boolean;
  indeterminate?: boolean;
}) => {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  if (indeterminate) {
    return <Icon className="size-5" path={mdiMinus} />;
  }

  if (disableAnimation) {
    return <Icon className="size-5" path={mdiCheck} />;
  }

  return (
    <motion.svg
      animate={checked ? 'checked' : 'unchecked'}
      className="size-5"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      tabIndex={-1}
      viewBox="0 0 24 24"
      whileHover="hover"
      whileTap="pressed"
    >
      <motion.path
        custom={checked}
        // This is a custom stroke-based path version of the MDI icon `mdiCheck`
        d="M4.2,12.8l4.8,4.8L20.3,6.3"
        style={{ opacity, pathLength }}
        variants={{
          checked: { pathLength: 1 },
          pressed: (isChecked: CheckedState) => ({
            pathLength: isChecked ? 1 : 0,
          }),
          unchecked: { pathLength: 0 },
        }}
      />
    </motion.svg>
  );
};

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      checked = false,
      className,
      colorScheme = 'neutral',
      defaultChecked = false,
      disableAnimation = false,
      onCheckedChange,
      ...props
    },
    ref,
  ) => {
    const isControlled = typeof onCheckedChange === 'function';

    const [internalChecked, setInternalChecked] = useState<CheckedState>(
      isControlled ? checked : defaultChecked,
    );

    return (
      <Root
        checked={isControlled ? checked : internalChecked}
        className={CheckboxVariants({ className, colorScheme })}
        defaultChecked={!isControlled ? defaultChecked : undefined}
        onCheckedChange={(e) => {
          setInternalChecked(e);
          onCheckedChange?.(e);
        }}
        {...props}
        data-ids="Checkbox"
        ref={ref}
      >
        <Indicator>
          <Check
            checked={isControlled ? checked : internalChecked}
            disableAnimation={disableAnimation}
            indeterminate={
              isControlled
                ? checked === 'indeterminate'
                : internalChecked === 'indeterminate'
            }
          />
        </Indicator>
      </Root>
    );
  },
);
Checkbox.displayName = 'Checkbox';

export default Checkbox;
