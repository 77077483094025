'use client';

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Combobox,
  ComboboxCancel,
  ComboboxItem,
  ComboboxList,
  ComboboxPopover,
  ComboboxProvider,
  useComboboxStore,
} from '@ariakit/react';
import { mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { cx } from 'class-variance-authority';

import IconButton from '@/src/components/IconButton';
import { MenuContentVariants } from '@/src/components/Menu/MenuContent/MenuContent.variants';
import { MenuItemVariants } from '@/src/components/Menu/MenuItem/MenuItem.variants';
import TextField from '@/src/components/TextField';
import Typography from '@/src/components/Typography';
import useDebounce from '@/src/utils/useDebounce';

import {
  type AutosuggestItem,
  type AutosuggestProps,
} from './Autosuggest.props';

export const Autosuggest = forwardRef<HTMLDivElement, AutosuggestProps>(
  (
    {
      autoFocus = false,
      autoSelect = false,
      className,
      'data-parent': dataParent,
      hideSearchIcon = false,
      name,
      onItemSelect = () => {},
      placeholder = 'Search',
      suggestionHandler,
      suggestions = [],
      ...props
    },
    forwardedRef,
  ) => {
    const comboboxStore = useComboboxStore();

    const comboboxRef = useRef<HTMLInputElement>(null);
    const listboxRef = useRef<HTMLDivElement>(null);
    const suggestionLockRef = useRef(false);
    const internalRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => internalRef.current!, []);

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [menuOptions, setMenuOptions] =
      useState<AutosuggestItem[]>(suggestions);

    const debouncedInputValue = useDebounce(inputValue, 250);

    if (suggestionHandler) {
      useEffect(() => {
        const getSuggestions = async () => {
          setIsOpen(true);
          setIsLoading(true);
          const suggestions = await suggestionHandler(debouncedInputValue);
          setMenuOptions(suggestions);
          setIsLoading(false);
        };

        if (suggestionLockRef.current) {
          // Suggestion engine currently locked, don't get new suggestions.
          // But unlock the engine for new changes.
          suggestionLockRef.current = false;
          return;
        }

        if (debouncedInputValue !== '') {
          getSuggestions();
        }
      }, [debouncedInputValue]);
    } else {
      useEffect(() => {
        const filteredOptions = suggestions.filter((suggestion) =>
          suggestion.value.toLowerCase().includes(inputValue.toLowerCase()),
        );
        setMenuOptions(filteredOptions);
      }, [inputValue]);
    }

    const renderMenuOptions = () => {
      if (isLoading) {
        return (
          <Typography
            as={ComboboxItem}
            className={MenuItemVariants()}
            disabled
            variant="caption1"
          >
            Searching...
          </Typography>
        );
      }

      if (!menuOptions.length && debouncedInputValue !== '') {
        return (
          <Typography
            as={ComboboxItem}
            className={MenuItemVariants()}
            disabled
            variant="caption1"
          >
            No results
          </Typography>
        );
      }

      return menuOptions.map((option) => {
        return (
          <Typography
            as={ComboboxItem}
            className={cx(
              MenuItemVariants(),
              'dark:data-[active-item]:bg-neutral-3',
              'data-[active-item]:bg-neutral-6',
              'outline-none',
            )}
            data-id={option['data-id'] || option.id}
            data-parent={dataParent || name}
            focusOnHover
            key={option.id}
            onClick={() => {
              // We don't want a search to refire when selecting an item, so we
              // prevent the next suggestion lookup from happening.
              suggestionLockRef.current = true;

              // Call the supplied callback
              onItemSelect({ ...option, 'data-parent': dataParent || name });

              // Reset the menu back to its initial state
              setMenuOptions(suggestions);
            }}
            value={option.value}
            variant="caption1"
          >
            {option.value}
          </Typography>
        );
      });
    };

    return (
      <div
        className={className}
        {...props}
        data-ids="Autosuggest"
        ref={internalRef}
      >
        <ComboboxProvider
          open={isOpen}
          setOpen={setIsOpen}
          setValue={setInputValue}
          store={comboboxStore}
        >
          <Combobox
            autoFocus={autoFocus}
            autoSelect={autoSelect}
            placeholder={placeholder}
            ref={comboboxRef}
            render={(comboboxProps) => (
              <TextField
                endAdornment={
                  <ComboboxCancel
                    hideWhenEmpty
                    onClick={() => {
                      setIsOpen(false);
                      setMenuOptions(suggestions);
                    }}
                    render={
                      <IconButton
                        aria-label="Clear value"
                        className="!p-1"
                        colorScheme="transparent"
                        compact
                        rounded
                        size="small"
                      >
                        <Icon className="size-5" path={mdiClose} />
                      </IconButton>
                    }
                  />
                }
                name={name}
                startAdornment={
                  !hideSearchIcon && (
                    <Icon className="size-5" path={mdiMagnify} />
                  )
                }
                {...comboboxProps}
              />
            )}
            showOnChange={!suggestionHandler}
            showOnClick={false}
          />
          <ComboboxPopover
            className="z-top"
            gutter={14}
            modal
            portalElement={internalRef.current}
            preventBodyScroll={false}
            sameWidth
            shift={hideSearchIcon ? -17 : -41}
          >
            <ComboboxList
              className={cx(
                MenuContentVariants({
                  collapsePadding: false,
                  theme: 'auto',
                }),
                {
                  hidden: debouncedInputValue === '',
                  'w-[calc(var(--popover-anchor-width)+4.3rem)]':
                    hideSearchIcon,
                  'w-[calc(var(--popover-anchor-width)+5.9rem)]':
                    !hideSearchIcon,
                },
              )}
              ref={listboxRef}
              role="listbox"
            >
              {renderMenuOptions()}
            </ComboboxList>
          </ComboboxPopover>
        </ComboboxProvider>
      </div>
    );
  },
);
Autosuggest.displayName = 'Autosuggest';

export default Autosuggest;
