'use client';

import { forwardRef, useContext } from 'react';
import { mdiCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { Indicator, Item } from '@radix-ui/react-radio-group';

import { RadioGroupContext } from '../RadioGroup.context';
import { type RadioGroupItemProps } from './RadioGroupItem.props';
import { RadioGroupItemVariants } from './RadioGroupItem.variants';

export const RadioGroupItem = forwardRef<
  HTMLButtonElement,
  RadioGroupItemProps
>(({ className, colorScheme, disableAnimation, ...props }, ref) => {
  const context = useContext(RadioGroupContext);

  // Compute certain props based on the radio groups configuration
  const computedColorScheme = colorScheme || context?.colorScheme || 'neutral';
  const computedDisableAnimation =
    disableAnimation || context?.disableAnimation;

  return (
    <Item
      className={RadioGroupItemVariants({
        className,
        colorScheme: computedColorScheme,
        disableAnimation: computedDisableAnimation,
      })}
      {...props}
      data-ids="Radio"
      ref={ref}
    >
      <Indicator>
        <Icon className="size-3.5" path={mdiCircle} />
      </Indicator>
    </Item>
  );
});
RadioGroupItem.displayName = 'RadioGroupItem';

export default RadioGroupItem;
