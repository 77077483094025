'use client';

import { forwardRef, useCallback, useEffect, useState } from 'react';
import { List } from '@radix-ui/react-tabs';

import {
  Carousel,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from '@/src/components/Carousel';

import { useTabs } from '../Tabs.context';
import { type TabsListProps } from './TabsList.props';
import {
  TabsListNavigationButtonVariants,
  TabsListVariants,
} from './TabsList.variants';

export const TabsList = forwardRef<HTMLDivElement, TabsListProps>(
  ({ children, className, disableDrag = false, ...props }, ref) => {
    const { activeTab, isJumpList, tabSize, valuePrefix } = useTabs();
    const [api, setApi] = useState<CarouselApi>();

    const scrollToTab = useCallback(
      (tabIndex: number) => {
        if (!api) {
          return;
        }

        const { scrollTarget, scrollTo } = api.internalEngine();
        const { distance } = scrollTarget.byIndex(tabIndex, 1);

        // Try to scroll the distance to the next tab, plus an offset so the
        // tab doesn't get hidden behind the navigation arrows. If we can't
        // get a distance, just scroll directly to the tab index.
        if (!isNaN(distance)) {
          scrollTo.distance(distance + (tabIndex > 0 ? 38 : 0), false);
        } else {
          scrollTo.index(tabIndex, 0);
        }
      },
      [api],
    );

    useEffect(() => {
      if (Array.isArray(children) && children.length > 1 && api) {
        const activeIndex = children.findIndex(
          (child) => activeTab === `${valuePrefix}-${child.props.value}`,
        );
        scrollToTab(activeIndex);
      }
    }, [activeTab]);

    return (
      <List
        asChild
        className={TabsListVariants({ className, jumpList: isJumpList })}
        {...props}
        data-ids="TabsList"
        ref={ref}
        role="tablist"
      >
        <Carousel
          as="nav"
          disableKeyboardControl
          opts={{
            watchDrag: ({ canScrollNext, canScrollPrev }) => {
              if (disableDrag) {
                return false;
              }

              const tabsScrollable = canScrollNext() || canScrollPrev();
              return tabsScrollable;
            },
          }}
          setApi={setApi}
        >
          <CarouselContent>{children}</CarouselContent>
          <CarouselPrevious
            className={TabsListNavigationButtonVariants({
              action: 'prev',
              tabSize,
            })}
            tabIndex={-1}
            title="Scroll tabs to the left"
          />
          <CarouselNext
            className={TabsListNavigationButtonVariants({
              action: 'next',
              tabSize,
            })}
            tabIndex={-1}
            title="Scroll tabs to the right"
          />
        </Carousel>
      </List>
    );
  },
);
TabsList.displayName = 'TabsList';

export default TabsList;
