'use client';

import { forwardRef } from 'react';
import { Content } from '@radix-ui/react-accordion';

import { type AccordionContentProps } from './AccordionContent.props';
import { AccordionContentVariants } from './AccordionContent.variants';

export const AccordionContent = forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, className, ...props }, ref) => {
  return (
    <Content
      className={AccordionContentVariants({ className })}
      {...props}
      data-ids="AccordionContent"
      ref={ref}
    >
      <div className="pb-4 pt-0">{children}</div>
    </Content>
  );
});
AccordionContent.displayName = 'AccordionContent';

export default AccordionContent;
