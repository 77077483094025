'use client';

import { forwardRef } from 'react';
import { Root, Thumb } from '@radix-ui/react-switch';

import { type SwitchProps } from './Switch.props';
import { SwitchThumbVariants, SwitchVariants } from './Switch.variants';

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ className, colorScheme = 'primary', size = 'medium', ...props }, ref) => {
    return (
      <Root
        className={SwitchVariants({
          className,
          colorScheme,
          size,
        })}
        {...props}
        data-ids="Switch"
        ref={ref}
      >
        <Thumb className={SwitchThumbVariants({ size })} />
      </Root>
    );
  },
);
Switch.displayName = 'Switch';

export default Switch;
