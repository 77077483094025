'use client';

import { forwardRef } from 'react';
import { mdiImageMultipleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { cx } from 'class-variance-authority';

import Button from '@/src/components/Button';
import { Card, CardMedia, type CardProps } from '@/src/components/Card';
import { type CardMediaProps } from '@/src/components/Card/CardMedia';
import Skeleton from '@/src/components/Skeleton';
import { Tabs, TabsItem, TabsList } from '@/src/components/Tabs';
import Typography from '@/src/components/Typography';
import useContainerBreakpoint from '@/src/utils/useContainerBreakpoint';

import { type MosaicImageGalleryProps } from './MosaicImageGallery.props';
import { MosaicImageGalleryVariants } from './MosaicImageGallery.variants';

export const MosaicImageGallery = forwardRef<
  HTMLDivElement,
  MosaicImageGalleryProps
>(
  (
    {
      categories = [],
      className,
      dataParent: parentDataParent,
      images = [],
      linkElement: parentLinkElement = 'a',
      loading = false,
      onCategoryChange = () => {},
      showAll = false,
      showFeatured = false,
      showFeaturedBreakpoint = '6xl',
      title,
      titleTypographyProps = {},
      ...props
    },
    ref,
  ) => {
    // Sizing rules defined so they can be referenced easily (and revered easily) in the loop.
    // 1.777 is not a magic number, it's 16 / 9, rounded to three decimal places for sizing
    // accuracy compared to the square image next to it.
    const rules = [
      { aspectRatio: '1/1', grow: 'grow' },
      { aspectRatio: '16/9', grow: 'grow-[1.777]' },
    ];

    // Generate loading placeholders array
    const loadingPlaceholders = loading
      ? Array.from(
          Array(typeof loading === 'boolean' ? 4 : Math.floor(loading)),
        )
      : [];

    const { ref: bpRef, result: metFeaturedBreakpoint } =
      useContainerBreakpoint(showFeaturedBreakpoint);

    // The "featured" image can only show if:
    // - `showAll` is `false`
    // - `showFeatured` is `true`
    // - The `showFeaturedBreakpoing` has been met
    // - There are enough images to maintain balance (5 or more)
    const shouldShowFeatured =
      !showAll && showFeatured && metFeaturedBreakpoint && images.length > 4;

    const processImages = () => {
      if (showAll || !images.length) {
        return { images };
      }

      // When less than four images provided, only show the first image
      if (images.length < 4) {
        return { images: [images[0]] };
      }

      // When the "featured" image is to be shown, split it off and limit the rest
      if (shouldShowFeatured) {
        const [featuredImage, ...restImages] = images;
        return { featuredImage, images: restImages.slice(0, 4) };
      }

      // By default, we only show four images
      return { images: images.slice(0, 4) };
    };

    const { featuredImage, images: processedImages } = processImages();

    // Breaking the images array up into pairs
    const imagePairs = (
      loading ? loadingPlaceholders : processedImages
    ).flatMap((_, i, a) => (i % 2 ? [] : [a.slice(i, i + 2)]));

    const renderCategories = () => {
      if (!categories.length) {
        return null;
      }

      const defaultTab = categories.find((category) => category.selected);
      return (
        <Tabs
          className="-mt-4"
          defaultValue={defaultTab?.id}
          onValueChange={onCategoryChange}
        >
          <TabsList>
            {categories.map((category) => (
              <TabsItem key={category.id} value={category.id}>
                {category.name}
              </TabsItem>
            ))}
          </TabsList>
        </Tabs>
      );
    };

    const renderTitle = () => {
      if (!title) {
        return null;
      }

      const { className: titleClassName, ...restTitleProps } =
        titleTypographyProps;

      return (
        <Typography
          as="h2"
          className={cx('@3xl/mosaic:typography-h4', titleClassName)}
          variant="h5"
          {...restTitleProps}
        >
          {title}
        </Typography>
      );
    };

    const renderFeaturedImage = () => {
      if (!shouldShowFeatured) {
        return null;
      }

      return (
        <div className="grow-[1.3] pr-6 @container/gallery">
          {renderImageCard(featuredImage!, 1, 1, loading)}
        </div>
      );
    };

    const renderImageCard = (
      image: MosaicImageGalleryProps['images'][0],
      row: number,
      col: number,
      loading: boolean | number,
    ) => {
      const isEvenRow = row % 2;
      const { aspectRatio, grow } = rules[isEvenRow ? col : 1 - col];

      if (loading) {
        return (
          <Skeleton
            className={`${grow} rounded @2xl/mosaic:rounded-lg`}
            key={`r-${row}-c-${col}`}
            style={{
              aspectRatio,
            }}
            variant="rectangular"
          />
        );
      }

      const {
        alt,
        dataId: imageDataId,
        dataParent: imageDataParent = parentDataParent,
        galleryCount = 0,
        href,
        linkElement: imageLinkElement = parentLinkElement,
        src,
        title: imageTitle,
      } = image;

      return (
        <Card
          as={(href ? imageLinkElement : undefined) as CardProps['as']}
          className={`group basis-0 ${grow}`}
          data-id={href ? imageDataId : undefined}
          data-parent={href ? imageDataParent : undefined}
          href={href}
          key={`r-${row}-c-${col}`}
          variant="hero"
        >
          <CardMedia
            alt={alt}
            aspectRatio={aspectRatio as CardMediaProps['aspectRatio']}
            className="[&>div]:rounded [&>div]:@2xl/mosaic:rounded-lg"
            src={src}
            zoomOnHover
          >
            <div className="flex h-full flex-col justify-between">
              <div className="flex w-full justify-end">
                {galleryCount > 0 && href && (
                  <Button
                    colorScheme="neutral-3"
                    ignoreTheme
                    size="small"
                    startIcon={
                      <Icon className="size-5" path={mdiImageMultipleOutline} />
                    }
                    tabIndex={-1}
                    typographyVariant="caption2"
                  >
                    <span className="hidden @xl/gallery:inline">See All </span>
                    {galleryCount}
                    <span className="hidden @xl/gallery:inline"> Photos</span>
                  </Button>
                )}
              </div>
              {imageTitle && (
                <div className="-m-2 flex bg-transparent from-neutral-1/80 from-30% to-transparent p-4 @lg/gallery:bg-gradient-to-t">
                  <Typography
                    className="hidden pt-4 text-neutral-8 group-hover:underline @lg/gallery:block"
                    disableColorScheme
                    variant="subtitle1"
                  >
                    {imageTitle}
                  </Typography>
                </div>
              )}
            </div>
          </CardMedia>
        </Card>
      );
    };

    return (
      <div
        className={MosaicImageGalleryVariants({ className })}
        {...props}
        data-ids="MosaicImageGallery"
        ref={ref}
      >
        <div className="flex flex-col gap-4 @3xl/mosaic:gap-6">
          {renderTitle()}
          {renderCategories()}
          <div
            className={cx('flex flex-row', {
              'cursor-wait': loading,
            })}
            ref={bpRef}
          >
            {renderFeaturedImage()}
            <div className="flex grow flex-col justify-between gap-4 @container/gallery @3xl/mosaic:gap-6">
              {imagePairs.map((imagePair, row) => {
                const isEvenRow = row % 2;
                return (
                  <div
                    className="flex gap-4 @3xl/mosaic:gap-6"
                    key={`r-${row}`}
                  >
                    {imagePair.map((image, col) =>
                      renderImageCard(image, row, col, loading),
                    )}
                    {imagePair.length === 1 && processedImages.length > 1 && (
                      <div className={`basis-0 ${rules[isEvenRow].grow}`} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
MosaicImageGallery.displayName = 'MosaicImageGallery';

export default MosaicImageGallery;
