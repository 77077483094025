'use client';

import { forwardRef, useEffect, useId, useRef, useState } from 'react';
import { Root } from '@radix-ui/react-tabs';

import { TabsContext } from './Tabs.context';
import { type TabsContextProps, type TabsProps } from './Tabs.props';
import { TabsVariants } from './Tabs.variants';

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  (
    {
      children,
      className,
      defaultValue,
      id,
      jumpList = false,
      onValueChange,
      tabSize = 'small',
      ...props
    },
    ref,
  ) => {
    const valuePrefix = id || useId();
    const [availableTabs, setAvailableTabs] = useState(() => new Set());
    const [contentVisibility, setContentVisibility] = useState<{
      [key: string]: boolean;
    }>({});
    const [activeTab, setActiveTab] = useState(
      `${valuePrefix}-${defaultValue}`,
    );
    const observerLock = useRef(false);

    if (jumpList) {
      useEffect(() => {
        // Use the results of the intersection observation from with
        // the tab content to determine what the active tab is.
        const visibleContent = [...availableTabs].find(
          (content) =>
            contentVisibility?.[content as keyof typeof contentVisibility],
        ) as string;

        if (visibleContent && !observerLock.current) {
          setActiveTab(visibleContent);
        }
      }, [contentVisibility]);
    }

    const scrollToContent = (contentId: string) => {
      const targetTabPanel = document.querySelector(
        `[data-ids="TabsContent"][data-value="${contentId}"]`,
      ) as HTMLDivElement;
      targetTabPanel?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    };

    const handleTabChange = async (tabId: string) => {
      // Call any custom supplied functions to happen on tab changes.
      // Make sure to only use the supplied value, and not the prefixed version.
      const valueWithoutPrefix = tabId.replace(`${valuePrefix}-`, '');
      onValueChange?.(valueWithoutPrefix);

      // Jump lists need only scroll to their content, the
      // intersection observer will handle setting the active tab
      if (jumpList) {
        observerLock.current = true;
        scrollToContent(tabId);

        // `onscrollend` is supported in all browsers except Safari,
        // so this handles native functionality and a fallback for Safari.
        const lockObserver = () => (observerLock.current = false);
        if ('onscrollend' in window) {
          document.onscrollend = lockObserver;
        } else {
          document.onscroll = () => {
            clearTimeout(window.scrollEndTimer);
            window.scrollEndTimer = setTimeout(lockObserver, 100);
          };
        }
      }

      setActiveTab(tabId);
    };

    // This is called from within the `TabContent` to report on visibility
    // when the component is being used as a jump list.
    const setTabContentVisibility: TabsContextProps['setTabContentVisibility'] =
      (tabId, visibility) => {
        setContentVisibility((prevState) => {
          const nextContentVisibility = { ...prevState };
          nextContentVisibility[tabId] = visibility;
          return nextContentVisibility;
        });
      };

    const registerTab = (value: string) => {
      setAvailableTabs((prevState) => new Set(prevState).add(value));
    };

    const unregisterTab = (value: string) => {
      setAvailableTabs((prevState) => {
        const tabs = new Set(prevState);
        tabs.delete(value);
        return tabs;
      });
    };

    return (
      <TabsContext.Provider
        value={{
          activeTab,
          isJumpList: jumpList,
          registerTab,
          setTabContentVisibility,
          tabSize,
          unregisterTab,
          valuePrefix,
        }}
      >
        <div className="@container/tabs" data-ids="Tabs" id={id}>
          <Root
            className={TabsVariants({ className, jumpList })}
            onValueChange={handleTabChange}
            {...props}
            ref={ref}
            value={activeTab}
          >
            {children}
          </Root>
        </div>
      </TabsContext.Provider>
    );
  },
);
Tabs.displayName = 'Tabs';

export default Tabs;
