'use client';

import { Root } from '@radix-ui/react-dropdown-menu';

import { type MenuProps } from './Menu.props';

export const Menu = ({ modal = true, ...props }: MenuProps) => (
  <Root modal={modal} {...props} data-ids="Menu" />
);
Menu.displayName = 'Menu';

export default Menu;
