'use client';

import { forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';

import reactNodeToText from '@/src/utils/reactNodeToText';

import { type EventDetail, type EventEmitterProps } from './EventEmitter.props';

const EventEmitter = forwardRef<HTMLElement, EventEmitterProps>(
  ({ events, ...props }, ref) => {
    // Prevent server-side rendering issues
    if (typeof window === 'object') {
      Object.keys(events).forEach((event) => {
        // TODO: Figure out how the heck to get TypeScript to play nicely with this
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { type, ...eventDetail } = (events as any)[event];

        const newEvent = new CustomEvent(type, {
          bubbles: true,
          detail: Object.keys(eventDetail).reduce((output, detailKey) => {
            if (typeof eventDetail[detailKey] !== 'string') {
              output[detailKey] = reactNodeToText(eventDetail[detailKey]);
              return output;
            }

            output[detailKey] = eventDetail[detailKey];
            return output;
          }, {} as EventDetail),
        });

        // TODO: Same as the above TODO
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (props as any)[event] = () => window.dispatchEvent(newEvent);
      });
    }

    return <Slot ref={ref} {...props} />;
  },
);
EventEmitter.displayName = 'EventEmitter';

export default EventEmitter;
